import { useState, useEffect } from 'react';

export interface Dimensions {
	width: number;
	height: number;
}

export const getWindowDimensions = (): Dimensions => {
	const { innerWidth: width, innerHeight: height } = window;
	return { width, height };
};

export const handleResize = (setWindowDimensions: (value: Dimensions) => void): void =>
	setWindowDimensions(getWindowDimensions());

const useWindowDimensions = (): Dimensions => {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		window.addEventListener('resize', () => handleResize(setWindowDimensions));
		return (): void => window.removeEventListener('resize', () => handleResize(setWindowDimensions));
	}, []);

	return windowDimensions;
};

export default useWindowDimensions;
