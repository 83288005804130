import React from 'react';

import { ReactComponent as LocationSceneIcon } from '../../assets/icons/location-scene.svg';
import { ReactComponent as ParkingSceneIcon } from '../../assets/icons/parking-scene.svg';
import { ReactComponent as RoadSceneIcon } from '../../assets/icons/road-scene.svg';

// Parking scenes
import ParkingAIcon from '../../assets/icons/parking-scenes/parking-A.svg';
import ParkingBIcon from '../../assets/icons/parking-scenes/parking-B.svg';

// Road scenes
import RoadAIcon from '../../assets/icons/road-scenes/road-A.svg';
import RoadBIcon from '../../assets/icons/road-scenes/road-B.svg';
import RoadCIcon from '../../assets/icons/road-scenes/road-C.svg';
import RoadDIcon from '../../assets/icons/road-scenes/road-D.svg';
import RoadEIcon from '../../assets/icons/road-scenes/road-E.svg';
import RoadFIcon from '../../assets/icons/road-scenes/road-F.svg';

import RoadAPhoto from '../../assets/icons/road-scenes/road-A-photo.svg';
import RoadBPhoto from '../../assets/icons/road-scenes/road-B-photo.svg';
import RoadCPhoto from '../../assets/icons/road-scenes/road-C-photo.svg';
import RoadDPhoto from '../../assets/icons/road-scenes/road-D-photo.svg';
import RoadEPhoto from '../../assets/icons/road-scenes/road-E-photo.svg';
import RoadFPhoto from '../../assets/icons/road-scenes/road-F-photo.svg';

import { IconProps } from '../../types/icon-props';

/*eslint-disable*/
const SCENES = [
	{
		value: 'location',
		currentLocation: true,
		icon: (props: IconProps) => <LocationSceneIcon {...props} />,
		text: 'Utilizar ubicación',
		to: '/:id/scene/location',
	},
	{
		value: 'parking',
		icon: (props: IconProps) => <ParkingSceneIcon {...props} />,
		text: 'Estacionamiento',
		to: '/:id/scene/parking',
	},
	{
		value: 'road',
		icon: (props: IconProps) => <RoadSceneIcon {...props} />,
		text: 'Carretera',
		to: '/:id/scene/road',
	},
];
/*eslint-disable*/

const PARKING_SCENES = [
	{
		value: 'parkingA',
		icon: ParkingAIcon,
		letter: 'A',
	},
	{
		value: 'parkingB',
		icon: ParkingBIcon,
		letter: 'B',
	},
];

const ROAD_SCENES = [
	{
		value: 'roadA',
		icon: RoadAIcon,
		letter: 'A',
		photo: RoadAPhoto,
	},
	{
		value: 'roadB',
		icon: RoadBIcon,
		letter: 'B',
		photo: RoadBPhoto,
	},
	{
		value: 'roadC',
		icon: RoadCIcon,
		letter: 'C',
		photo: RoadCPhoto,
	},
	{
		value: 'roadD',
		icon: RoadDIcon,
		letter: 'D',
		photo: RoadDPhoto,
	},
	{
		value: 'roadE',
		icon: RoadEIcon,
		letter: 'E',
		photo: RoadEPhoto,
	},
	{
		value: 'roadF',
		icon: RoadFIcon,
		letter: 'F',
		photo: RoadFPhoto,
	},
];

export { SCENES, PARKING_SCENES, ROAD_SCENES };
