import { Photo, AppContext } from 'context/interfaces';
import { sections, BRAND } from 'shared/utils';
interface PhotoBySectionsType {
	[key: string]: Photo[];
}

interface CompletedSectionType {
	[key: string]: boolean;
}

interface RequiredSectionType {
	[key: string]: boolean;
}

export const hasRequiredPhotos = (ctx: AppContext): boolean => {
	const { photos } = ctx;
	const completed = !photos.some((p) => p.required && !p.awsUrl);
	return completed;
};

export const getPhotoSectionsStatus = (photos: Photo[]): any => {
	const photoBySections: PhotoBySectionsType = {};
	const completedSection: CompletedSectionType = {};
	let requiredSection: RequiredSectionType = {};
	sections[BRAND].forEach((section) => {
		if (section.required) {
			requiredSection = { ...requiredSection, [section.sectionId]: true };
			photoBySections[section.sectionId] = [];
			section.photosIds?.forEach((id) => {
				photos.forEach((photo) => {
					if (id === photo.id) photoBySections[section.sectionId].push(photo);
				});
			});
		} else {
			requiredSection = { ...requiredSection, [section.sectionId]: false };
		}
	});

	for (const sectionId in photoBySections) {
		completedSection[sectionId] = photoBySections[sectionId]
			.filter((section) => section.required)
			.every((p) => p.awsUrl);
	}
	return { completedSection, requiredSection };
};
