import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import ClientForm from '../../components/information/ClientForm';
import '../../assets/styles/screens/policy/ClientInfo.scss';
import { useParams } from 'react-router';
import { urlIdRewrite } from 'shared/utils';

interface Props {
	screenKey: any;
	title: string;
	to: string;
	isCounterpartInfo?: boolean;
}

const ClientInfoScreen = (props: Props): JSX.Element => {
	const { id } = useParams<{ id: string }>();

	return (
		<div className="client-info">
			<Breadcrumbs currentStep={props.screenKey} />
			<ClientForm
				screenKey={props.screenKey}
				title={props.title}
				to={urlIdRewrite(props.to, id)}
				isCounterpartInfo={props.isCounterpartInfo}
			/>
		</div>
	);
};

export default ClientInfoScreen;
