import TagManager from 'react-gtm-module';
import { ActionType, AppContext } from '../../context/interfaces';
import { dateToLocaleString } from './dateUtils';
import { BRAND } from 'shared/utils';

const brand = BRAND?.toLocaleLowerCase() || '';

const baseModelForGT = (context: AppContext): any => {
	const time = dateToLocaleString();
	return {
		iVirtualId: context.inspectionId,
		clientBrand: brand,
		userActiveId: context.userSessionId,
		timeStamp: time,
	};
};

export const surveyReviewGTM = (context: AppContext, reviewExp: number, reviewRecom: number): void => {
	const baseData = baseModelForGT(context);

	const tagManagerExperienceArgs = {
		dataLayer: {
			...baseData,
			event: 'survey-review-experience',
			type: 'Survey-Review-Exp-By-User',
			review: reviewExp,
		},
	};
	TagManager.dataLayer(tagManagerExperienceArgs);

	const tagManagerRecommendedArgs = {
		dataLayer: {
			...baseData,
			event: 'survey-review-recommend',
			type: 'Survey-Review-Recom-By-User',
			review: reviewRecom,
		},
	};
	TagManager.dataLayer(tagManagerRecommendedArgs);
};

export const userSessionGTM = (context: AppContext): void => {
	const baseData = baseModelForGT(context);

	const tagManagerArgs = {
		dataLayer: {
			...baseData,
			event: 'initial-user-session',
			type: 'User-Session',
		},
	};
	TagManager.dataLayer(tagManagerArgs);
};

export const initializeSectionGTM = (context: AppContext, path: string): void => {
	const inspId = '/' + (context.inspectionId ?? 'none');
	const filterPath = path.replace(inspId, '');

	if (filterPath.length >= 3) {
		const baseData = baseModelForGT(context);
		const tagManagerArgs = {
			dataLayer: {
				...baseData,
				event: 'user-render-section',
				type: 'Section-Render-By-User',
				sectionPath: filterPath,
				effectiveTime: 0,
			},
		};
		TagManager.dataLayer(tagManagerArgs);

		context.dispatch({
			type: ActionType.SET_INITIAL_TIME_BY_SECTION,
			data: performance.now(),
		});
	}
};

export const completeSectionGTM = (context: AppContext, path: string): void => {
	const inspId = '/' + (context.inspectionId ?? 'none');
	const filterPath = path.replace(inspId, '');

	if (filterPath.length >= 3) {
		const baseData = baseModelForGT(context);
		const effectiveTime = (performance.now() - context.initSectionTime) / 1000;

		const tagManagerArgs = {
			dataLayer: {
				...baseData,
				event: 'user-complete-section',
				type: 'Section-Complete-By-User',
				sectionPath: filterPath,
				effectiveTime,
			},
		};
		TagManager.dataLayer(tagManagerArgs);
	}
};
