import React from 'react';
import cn from 'classnames';
import '../../assets/styles/components/ui/TextInput.scss';

interface TextInputProps {
	label?: string;
	placeholder?: string;
	readonly?: boolean;
	required?: boolean;
	message?: string;
	type?: string;
	inputMode?: any;
	inputSize?: number;
	value?: string | undefined;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const TextInput = (props: TextInputProps): JSX.Element => {
	const isOnError = props.message;
	const errorMsg = isOnError ? <span data-testid="error-msg">{props.message}</span> : '';
	const classes = cn('text-input', { 'has-error': isOnError });

	return (
		<div className={classes}>
			<input
				data-testid="text-input"
				id={props.label}
				type={props.type}
				inputMode={props.inputMode}
				maxLength={props.inputSize}
				readOnly={props.readonly}
				placeholder={props.placeholder}
				value={props.value}
				required
				onChange={props.onChange}
				onBlur={props.onBlur}
			/>
			{props.label ? <label htmlFor={props.label}>{props.label}</label> : ''}
			{errorMsg}
		</div>
	);
};

export default TextInput;
