import React from 'react';
import { progressByBrand } from 'shared/utils';
import classNames from 'classnames';
import '../assets/styles/components/ProgressBar.scss';

const ProgressBar = ({ screenIndex }: { screenIndex: number }): JSX.Element => {
	const totalScreen = Object.values(progressByBrand);
	return (
		<div className="progress-bar">
			{totalScreen.map((_, index) => {
				const className = classNames('bar', { 'bar-color': screenIndex > index });
				return <div key={index} className={className}></div>;
			})}
		</div>
	);
};

export default ProgressBar;
