import React, { useContext } from 'react';
import { Context } from '../../context/Context';
import { ActionType, AppContext, FieldStateKey, IndividualState } from '../../context/interfaces';
import '../../assets/styles/components/location/SelectLocation.scss';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { useParams } from 'react-router';
import HeliosClient from 'context/Helios';
import { setFixedObjectPhoto } from 'context/reducer';

const SelectData = (): JSX.Element => {
	const selectedIncidentOptions = [
		{ id: '', name: null },
		{ id: '1', name: 'Contra objeto fijo' },
		{ id: '2', name: 'Contra un animal' },
		{ id: '3', name: 'Robo Parcial' },
		{ id: '4', name: 'Daños a tu vehículo estacionado' },
	];
	const { id } = useParams<{ id: string }>();
	const context = useContext(Context) as AppContext;
	const { dispatch } = context;

	const incident = {
		fieldKey: FieldStateKey.INCIDENT,
		label: '¿El incidente fue?',
		type: 'selected',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.sex,
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: '',
		selectedData: selectedIncidentOptions,
	};

	const saveIncident = (selectedValue: number): void => {
		dispatch({
			type: ActionType.SET_IS_FIXED_OBJECT,
			data: 'isFixedObject',
		});

		dispatch({ type: ActionType.SET_INCIDENT_TYPE, data: selectedValue });

		setFixedObjectPhoto(context, 'isFixedObject');

		context.incidentType = selectedValue;

		new HeliosClient().saveInspection(context, id);
	};

	return (
		<div className="client-form">
			<div className="container">
				<div key={incident.label} className="select-input">
					<FormControl variant="outlined">
						<InputLabel htmlFor="outlined-age-native-simple">{incident.label}</InputLabel>
						<Select
							native
							value={context.incidentType}
							onChange={(e) => saveIncident(Number(e.target.value))}
							label={incident.label}
						>
							{incident.selectedData &&
								incident.selectedData.map((d) => {
									if (d.name) {
										return (
											<option key={d.id} value={d.id}>
												{d.name}
											</option>
										);
									} else {
										return <option key={d.id}></option>;
									}
								})}
						</Select>
					</FormControl>
				</div>
			</div>
		</div>
	);
};

export default SelectData;
