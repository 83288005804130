import React, { ReactElement } from 'react';
import Portal from '../portal';
import { ReactComponent as LoadingIcon } from '../../assets/styles/imgs/loading.svg';
import '../../assets/styles/components/camera/Loading.scss';

interface LoadingProps {
	message?: string;
}

const Loading = ({ message }: LoadingProps): ReactElement => {
	return (
		<Portal>
			<div className="loading">
				<LoadingIcon data-testid="loading-icon" />
				<h2>{message}</h2>
			</div>
		</Portal>
	);
};

export default Loading;
