import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Context } from '../../../context/Context';
import './VideoUploadScreen.scss';
import gifVideoSteps from '../../../assets/icons/rotate-vehicle.gif';
import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/i-info.svg';
import { ReactComponent as VideoIcon } from '../../../assets/icons/i-360video.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Button, Card, CardActionArea, CardMedia } from '@mui/material';
import clsx from 'clsx';
import { ReactComponent as VideoProcessedImg } from '../../../assets/icons/carga_completa.svg';
import ConfirmButton from 'components/common/ConfirmButton';
import Portal from 'components/portal/Portal';
import Loading from 'components/common/Loading';
import { uploadMediaToServer, deleteMediaToServer } from 'context/Utils';
import { ActionType, AppContext } from 'context/interfaces';
import { VideoRecord } from '@connect-assistance/connect-react-components-lib';

function formatDuration(duration: number): string {
	return Math.round(duration).toString();
}

/**
 * Taken from: https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes: number, si = false, dp = 1) {
	const thresh = si ? 1000 : 1024;

	if (Math.abs(bytes) < thresh) {
		return bytes + ' B';
	}

	const units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
	let u = -1;
	const r = 10 ** dp;

	do {
		bytes /= thresh;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

	return bytes.toFixed(dp) + ' ' + units[u];
}

// Assuming slow internet of 144Kbps, it'll take ~6 secs per MB
// iOS creates video of size ~1MB per 10 seconds of recording.
const VIDEO_DURATION_TEXT = 30; // seconds
const VIDEO_DURATION_LIMIT = 31; // in seconds
const VIDEO_SIZE_LIMIT = 70; // in MB
const BYTES_PER_MB = 1000000;
// Checking if the device support getUserMedia
const videoRecordAllowed =
	!!window.MediaRecorder &&
	!!navigator.mediaDevices &&
	!!navigator.mediaDevices.enumerateDevices &&
	!!navigator.mediaDevices.getUserMedia;
// Check is it is an iOS device
const isiOS = !!navigator.userAgent && /ipad|ipod|iphone/i.test(navigator.userAgent);

/**
 * How video uploading works is that onChange we save the file and
 * objectUrl to local state and check validity. If invalid, remove
 * local state. If valid, start uploading and set remote url. UI
 * will display remote url if exist, else local url.
 */
const VideoUploadScreen: FunctionComponent = (): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	const ctx = useContext(Context) as AppContext;
	const { photos } = ctx;
	const currentVideoFile = photos.find((photo) => photo.id === 'vehicle_video') ?? null;
	const remoteUrl = currentVideoFile?.awsUrl;
	const history = useHistory();

	const videoRef = useRef<HTMLVideoElement>(null);
	const [videoFile, setVideoFile] = useState<File | null>(null);
	const [objectUrl, setObjectUrl] = useState<string | null>(null);
	const [duration, setDuration] = useState<number | null>(null);
	const [invalidVideo, setInvalidVideo] = useState(true);
	const [loadVideo, setLoadVideo] = useState(true);
	const [loading, setLoading] = useState(false);
	const [showIntroMsg, setShowIntroMsg] = useState(true);
	const [showVideoRecord, setShowVideoRecord] = useState<boolean>(false);
	const [useVideoRecord, setUseVideoRecord] = useState<boolean>(videoRecordAllowed);
	const [changeOption, setChangeOption] = useState<boolean>(false);

	const onChange: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
		if (!event.target.files) {
			return;
		}

		const file = event.target.files[0];
		setVideoFile(file);
		const url = URL.createObjectURL(file); // Remember to "revoke" when done. (URL.revokeObjectURL(objectUrl);)
		setObjectUrl(url); // for displaying locally
	};

	const onLoadedMetadata = () => {
		if (!videoRef.current) {
			return;
		}

		const video = videoRef.current;
		if (video.duration === Infinity) {
			// Theres a special weird case in which we need to force the browser
			//  to re-parse the video so that we get duration.
			// Inspired by  https://www.thecodehubs.com/infinity-audio-video-duration-issue-fixed-using-javascript/
			//  and https://stackoverflow.com/questions/52167815/html5-video-duration-infinity-while-readystate-4.
			video.currentTime = 1e101;
			video.ontimeupdate = function () {
				// eslint-disable-next-line
				this.ontimeupdate = () => {};
				video.currentTime = 0;
				setDuration(video.duration);
			};
		} else {
			setDuration(video.duration);
		}
	};

	// This useEffect is to check validity of video and upload if passes
	useEffect(() => {
		if (objectUrl && videoFile && duration && ctx.eventId && !loading) {
			const size = videoFile.size; // in bytes
			const valid = size <= VIDEO_SIZE_LIMIT * BYTES_PER_MB && duration <= VIDEO_DURATION_LIMIT;

			if (valid) {
				setInvalidVideo(false);
				setLoading(true);
				// start uploading... to substitute localUrl for remoteUrl.
				// after done uploading, set remoteUrl and load from there(?).
				uploadMediaToServer(String(ctx.eventId), 'VEHICLE_VIDEO', videoFile, currentVideoFile!.section).then((url) => {
					setLoading(false);
					ctx.dispatch({
						type: ActionType.ADD_PHOTO,
						data: { photoId: 'vehicle_video', awsUrl: url },
					});
				});
			} else {
				setInvalidVideo(true);
			}
		} else if (!objectUrl && loadVideo) {
			if (currentVideoFile) {
				setObjectUrl(currentVideoFile.awsUrl ?? '');

				if (currentVideoFile.awsUrl) {
					setInvalidVideo(false);
				}
			}

			setLoadVideo(false);
		}
		// eslint-disable-next-line
	}, [objectUrl, videoFile, duration]);

	useEffect(() => {
		if (remoteUrl) {
			setInvalidVideo(false);
		}
	}, [remoteUrl]);

	const retakeVideo = (): void => {
		setVideoFile(null);
		setObjectUrl(null);
		ctx.dispatch({
			type: ActionType.ADD_PHOTO,
			data: { photoId: 'vehicle_video', awsUrl: undefined },
		});
		setInvalidVideo(true);
	};

	const clearInvalidVideo = async () => {
		if (remoteUrl) {
			// Do in-memory work first, in case server fails, the user can still proceed
			URL.revokeObjectURL(remoteUrl);
			retakeVideo();

			await deleteMediaToServer(remoteUrl);
		}
	};

	const size = videoFile ? videoFile.size : null;

	const recordingComplete = (videoBlob: Blob) => {
		const tmpFile = new File([videoBlob], 'video', {
			type: videoBlob.type,
		});
		const url = URL.createObjectURL(tmpFile); // Remember to "revoke" when done. (URL.revokeObjectURL(objectUrl);)
		setVideoFile(tmpFile);
		setObjectUrl(url); // for displaying locally
		setShowVideoRecord(false);
	};

	const handleChangeOption = () => {
		setShowVideoRecord(false);
		setUseVideoRecord(false);
		setChangeOption(true);
	};

	return (
		<>
			{showVideoRecord && <VideoRecord onRecordingComplete={recordingComplete} onError={handleChangeOption} />}
			{!showVideoRecord && (
				<div className="photo-section-screen video-section-screen">
					{loading && <Loading message="Procesando Video" />}
					<div className="container">
						{invalidVideo && size && duration && (
							<Portal>
								<h2>Tamaño del video</h2>
								<div>
									Video debe ser <i>menos de</i> <b>{VIDEO_DURATION_TEXT} segundos</b> y <b>{VIDEO_SIZE_LIMIT} MB</b>{' '}
									<i>de tamaño</i>.
									<Alert id="size-alert" severity="error">
										El video actual dura <b>{formatDuration(duration)} segundos</b> y tiene un tamaño de{' '}
										<b>{humanFileSize(size, true, 1)}</b>.
									</Alert>
								</div>
								<ConfirmButton to="#" onClick={retakeVideo} text="Retomar" />
							</Portal>
						)}
						{showIntroMsg && (
							<Portal>
								<div className="photo-help-container">
									<h2 style={{ color: 'white', padding: '5px', textAlign: 'center' }}>¿Cómo realizar el video?</h2>
									<Card>
										<CardActionArea>
											<CardMedia component="img" image={gifVideoSteps} alt="video img" />
										</CardActionArea>
									</Card>
									<div style={{ padding: '20px', color: 'white', textAlign: 'center', fontSize: '18px' }}>
										Camina alrededor del vehículo para realizar el vídeo cómo se muestra en la imagen de arriba.
									</div>
									<ConfirmButton
										onClick={() => {
											setShowIntroMsg(false);
										}}
										to="#"
										text="Entendido"
									/>
								</div>
							</Portal>
						)}
						<h3 className="lead">
							<BackIcon onClick={history.goBack} />
							<span>Video 360°</span>
							<InfoIcon
								onClick={() => {
									setShowIntroMsg(true);
								}}
							/>
						</h3>
						<div className="photos-scrollable-area">
							<p className="video-instructions">Recuerda tener el vehículo limpio y contar con buena iluminación.</p>
							{!objectUrl && !remoteUrl && (
								<Alert className="info-alert" severity="info">
									Video debe durar menos de {VIDEO_DURATION_TEXT} segundos.
								</Alert>
							)}
							{/* CASE: Meadia recorder and media devices allowed, so we can use react-video-recorder component */}
							{!objectUrl && !remoteUrl && useVideoRecord && !isiOS && (
								<button className="custom-file-upload" onClick={() => setShowVideoRecord(true)}>
									<VideoIcon className="theme-svg-icon" /> Grabar video 360°
								</button>
							)}
							{/* FALLBACK AND iOS CASE : Meadia recorder and media devices not allowed, so we must use input video */}
							{!objectUrl && !remoteUrl && (!useVideoRecord || isiOS) && (
								<>
									<label htmlFor="file-input" className="custom-file-upload">
										<VideoIcon className="theme-svg-icon" /> Grabar video 360°
									</label>
									<input id="file-input" type="file" accept="video/*" capture="environment" onChange={onChange} />
								</>
							)}
							{!objectUrl && !isiOS && changeOption && (
								<Alert className="info-alert" severity="warning">
									Oops. Algo salió mal, vuelve a intentarlo.
								</Alert>
							)}
							{/* TODO: How to deal with refresh? Can we load remoteUrl if exist else localUrl */}
							{(objectUrl || remoteUrl) && (
								<>
									{isiOS && !objectUrl?.includes('blob') ? (
										<>
											<Alert className="info-alert" severity="success">
												Tu video ya ha sido cargado al sistema.
											</Alert>
											<VideoProcessedImg className="video-processed" />
										</>
									) : (
										<video
											ref={videoRef}
											preload="metadata"
											width="100%"
											height="200"
											controls
											src={objectUrl || remoteUrl} // prefer local objectUrl if available
											onLoadedMetadata={onLoadedMetadata}
										></video>
									)}
									<Button
										style={{ margin: '20px' }}
										variant="contained"
										className={clsx('delete-btn button')}
										onClick={clearInvalidVideo}
									>
										<DeleteIcon />
										Eliminar y retomar
									</Button>
								</>
							)}
							{size && duration && (
								<Alert severity="info">
									Video cargado correctamente y tiene un tamaño de <b>{humanFileSize(size, true, 1)}</b>.
								</Alert>
							)}
						</div>
					</div>
					<div className="button-panel">
						<ConfirmButton
							disabled={invalidVideo}
							onClick={() => history.push('/' + id + '/photo/sections')}
							text="Continuar"
							to="#"
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default VideoUploadScreen;
