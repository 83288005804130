import { AppContext } from 'context/interfaces';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../context/Context';

const PENDO_APIKEY = process.env.REACT_APP_PENDO_APIKEY;

const COUNTRY_BRANCHES: Record<string, string> = {
	PTY: 'Panama',
	PRI: 'Puerto Rico',
	CRC: 'Costa Rica',
	COL: 'Colombia',
	MEX: 'Mexico',
} as const;

const COMPANY_REFERENCE: Record<string, string> = {
	CSM: '11',
	ASSA: '2',
	LAFISE: '6',
	OCEANICA: '4',
	QUALITAS: '1',
	MULTINATIONAL: '34',
	PREMIER: '25',
} as const;

export interface VisitorPendo {
	id?: string;
	email?: string;
	phone?: string;
	branch?: string;
}

export interface AccountPendo {
	id?: string;
	account?: string;
}

export const loadPendoScript = (data: AppContext): Promise<void> => {
	return new Promise((resolve, reject) => {
		const cleanup = () => {
			if (script && script.parentNode) {
				script.parentNode.removeChild(script);
			}
		};

		if (window.pendo?.isReady()) {
			initializePendo(data);
			resolve();
			return;
		}

		const script = document.createElement('script');
		script.src = `https://cdn.pendo.io/agent/static/${PENDO_APIKEY}/pendo.js`;
		script.async = true;

		script.onload = () => {
			initializePendo(data);
			console.log('Pendo script loaded and initialized.');
			resolve();
		};

		script.onerror = () => {
			cleanup();
			console.error('Failed to load the Pendo script.');
			reject(new Error('Failed to load the Pendo script'));
		};

		window.addEventListener('unload', cleanup);
		document.body.appendChild(script);
	});
};

export const encodeBase64 = (data: string): string => {
	return btoa(data);
};

const decodeBase64 = (encryptedData: string): string => {
	return atob(encryptedData); // Decodifica el dato de Base64 a su valor original
};

export const initializePendo = (data: AppContext): void => {
	try {
		if (
			data?.insured?.fields?.email !== '' &&
			data?.insured?.fields?.email !== undefined &&
			data?.insured?.fields?.email !== null
		) {
			const countryCode = String(localStorage.getItem('countryCode'));
			if (!countryCode) {
				throw new Error('Código de país no disponible');
			}

			const contactEmail = data?.insured?.fields?.email;
			const contactPhone = data?.insured?.fields?.driverPhone;
			const accountName = String(localStorage.getItem('account'));
			if (!accountName) {
				throw new Error('Nombre de cuenta no disponible');
			}

			const accountReference = COMPANY_REFERENCE[decodeBase64(accountName).toUpperCase()] || '';
			const branch = COUNTRY_BRANCHES[decodeBase64(countryCode)] || '';

			window.pendo.initialize({
				apiKey: String(PENDO_APIKEY),
				visitor: {
					id: contactEmail,
					email: contactEmail,
					phone: contactPhone,
					branch,
				},
				account: {
					id: accountReference,
					account: decodeBase64(accountName),
				},
			});
			console.log('Pendo initialized successfully.');
		}
	} catch (error) {
		console.error('Error al inicializar Pendo:', error);
	}
};

export const usePendoInitialization = () => {
	const context = useContext(Context) as AppContext;
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		const initPendo = async () => {
			try {
				setIsLoading(true);
				await loadPendoScript(context);
			} catch (err) {
				setError(err instanceof Error ? err : new Error('Error desconocido'));
				console.error('Error al cargar Pendo:', err);
			} finally {
				setIsLoading(false);
			}
		};

		initPendo();
	}, [context]);

	return { isLoading, error };
};
