import './AlertDeviceScreen.scss';
import React from 'react';
import screenImage from '../../assets/imgs/require-mobile-device.svg';
import noImage from '../../assets/imgs/no-image.png';
import { logo } from '../../context/Utils';
import { BRAND } from 'shared/utils';
import { Brands } from '../../context/interfaces';

const AlertDeviceScreen = (): JSX.Element => {
	return (
		<div className="alert-env">
			<picture
				id="logo"
				className={
					BRAND === Brands.CSM || BRAND === Brands.OCEANICA
						? 'smallImg'
						: BRAND === Brands.QUALITAS
						? 'qualitasImg'
						: ''
				}
			>
				<source srcSet={logo} />
				<img src={noImage} alt="Application Logo" />
			</picture>
			<picture id="screen-img">
				<source srcSet={screenImage} />
				<img
					src={noImage}
					alt="Screen"
					className={BRAND === Brands.CSM || BRAND === Brands.OCEANICA || BRAND === Brands.QUALITAS ? 'screen-img' : ''}
				/>
			</picture>
			<p>
				Se requiere seguir el proceso en un <b>dispositivo móvil</b>.
			</p>
			<h3>
				Powered by <span>Connect Assistance</span>
			</h3>
		</div>
	);
};

export default AlertDeviceScreen;
