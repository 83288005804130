import React from 'react';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

interface DatePickerProps {
	value: Date | string | null;
	onChange: any;
}

const TimePicker = ({ value, onChange }: DatePickerProps): JSX.Element => {
	const time = value !== null ? value?.toString()?.split(':') : null;
	const timeValue = time ? new Date().setHours(Number(time[0]), Number(time[1])) : null;
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardTimePicker
				placeholder="Hora"
				value={timeValue}
				onChange={onChange}
				KeyboardButtonProps={{
					'aria-label': 'change time',
				}}
				inputVariant="outlined"
				fullWidth
				InputProps={{ readOnly: true }}
				data-testid="time picker"
			/>
		</MuiPickersUtilsProvider>
	);
};

export default TimePicker;
