import React from 'react';
import '../../assets/styles/components/vehicle/DamageCheck.scss';

interface DamageCheckProps {
	areaId: number;
	selected: boolean;
	onClick: () => void;
}

const DamageCheck = (props: DamageCheckProps): JSX.Element => {
	const classes = `damage-check ${props.selected ? 'selected' : ''}`;
	return (
		<button data-testid="damageCheck" className={classes} onClick={props.onClick}>
			{props.areaId}
		</button>
	);
};

export default DamageCheck;
