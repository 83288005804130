export enum CountryCode {
	CRC = 'CRC',
	PRI = 'PRI',
	PAN = 'PAN',
	COL = 'COL',
	MEX = 'MEX',
}

export enum ActionType {
	// tagManager
	SET_INITIAL_TIME_BY_SECTION = 'SET_INITIAL_TIME_BY_SECTION',

	// Event State
	SET_EVENT = 'SET_EVENT',
	SET_FIELD_VALUE = 'SET_FIELD_VALUE',
	HYDRATE_STATE = 'HYDRATE_STATE',

	// Map
	SET_COORDS = 'SET_COORDS',
	SET_ADDRESS = 'SET_ADDRESS',
	SET_LOCATION = 'SET_LOCATION',
	SET_MANUAL_LOCATION = 'SET_MANUAL_LOCATION',
	SET_LOCATION_ZONES = 'SET_LOCATION_ZONES',

	// Selection
	SET_ACCIDENT_TYPE = 'SET_ACCIDENT_TYPE',
	SET_IS_VEHICLE = 'SET_IS_VEHICLE',
	SET_IS_FIXED_OBJECT = 'SET_IS_FIXED_OBJECT',
	SET_IS_DEPOSIT = 'SET_IS_DEPOSIT',
	SET_DIRECT_DEPOSIT = 'SET_DIRECT_DEPOSIT',
	SET_DEPOSIT_ACCOUNT_TYPE = 'SET_DEPOSIT_ACCOUNT_TYPE',
	SET_DEPOSIT_SIGNATURE = 'SET_DEPOSIT_SIGNATURE',

	SET_NARRATION_TEXT = 'SET_NARRATION_TEXT',
	SET_NARRATION_ADITIONAL_TEXT = 'SET_NARRATION_ADITIONAL_TEXT',
	SET_ALERT_NARRATION_TEXT = 'SET_ALERT_NARRATION_TEXT',
	SET_DAMAGE = 'SET_DAMAGE',
	SET_SKETCH = 'SET_SKETCH',
	SET_SKETCH_ICONS = 'SET_SKETCH_ICONS',
	REMOVE_SKETCH_ICON = 'REMOVE_SKETCH_ICON',
	SET_SKETCH_ROTATE_TRIGGER = 'SET_SKETCH_ROTATE_TRIGGER',
	SET_EXACT_DIRECTION = 'SET_EXACT_DIRECTION',
	SET_LEVEL_LOCATION = 'SET_LEVEL_LOCATION',
	SET_INCIDENT_TYPE = 'SET_INCIDENT_TYPE',

	// Summary > Responsibility
	SET_RESPONSIBLE = 'SET_RESPONSIBLE',
	SET_LEGAL_ASSISTANCE = 'SET_LEGAL_ASSISTANCE',
	SET_INSURED_INITIALS = 'SET_INSURED_INITIALS',

	// POLICIVE PART
	SET_POLICIVE_PART = 'SET_POLICIVE_PART',
	SET_NUMBER_PART = 'SET_NUMBER_PART',

	// Photos
	ADD_PHOTO = 'ADD_PHOTO',
	POPULATE_PHOTOS = 'POPULATE_PHOTOS',
	RETAKE_PHOTO = 'RETAKE_PHOTO',
	DELETE_PHOTO = 'DELETE_PHOTO',
	ADD_PHOTO_GALLERY = 'ADD_PHOTO_GALLERY',
	DELETE_PHOTO_GALLERY = 'DELETE_PHOTO_GALLERY',
	UPDATE_PHOTO_LIST = 'UPDATE_PHOTO_LIST',
	UPDATE_VISITED_SECTION = 'UPDATE_VISITED_SECTION',

	SET_SIGNATURE = 'SET_SIGNATURE',

	//LOCATION
	SET_GPS_IS_ACTIVE = 'SET_GPS_IS_ACTIVE',
	SET_TIME = 'SET_TIME',
	SET_DATE = 'SET_DATE',

	//SKETCH
	SET_SCENE_PHOTO = 'SET_SCENE_PHOTO',
	SET_SHOW_INFO_PHOTOS = 'SET_SHOW_INFO_PHOTOS',
}

export enum DamageAreas {
	FRONT_END = 'Parte Frontal', // area 1, 2, 14, 15
	RIGHT_SIDE = 'Lateral Derecho', // area 3, 4, 5, 6
	REAR_END = 'Parte Trasera', // area 7, 8, 9, 19
	LEFT_SIDE = 'Lateral Izquierdo', // area 10, 11, 12, 13
	TOP = 'Techo', // area 16, 17, 18
}

export enum PhotoSection {
	PANORAMIC = 1, // Panoramica
	INSURED_VEHICLE = 2, // VehiculoAsegurado
	INSURED_DOCUMENTS = 3, // DocumentosAsegurado
	COUNTERPARTS = 4, // Terceros
	COUNTERPARTS_DOCUMENTS = 5, // DocumentosTerceros
	OTHERS = 6, // Otras
	INSURED_LICENSE = 20,
	INSURED_VEHICLE_LICENSE = 21,
	INSURED_ODOMETER = 22,
	INSURED_MARBETE = 23,
	INSURED_VIN = 24,
	INSURED_TABLILLA = 25,
	INSURED_VEH_PHOTOS = 26,
	INSURED_VEH_DAMAGE_PHOTOS = 27,
	INSURED_VEH_PANORAMIC = 28,
	INSURED_OTHERS_DOCS = 29,
	INJURED_LICENSE = 8,
	INJURED_VEHICLE_LICENSE = 9,
	INJURED_ODOMETER = 10,
	INJURED_MARBETE = 11,
	INJURED_VIN = 12,
	INJURED_TABLILLA = 13,
	INJURED_VEH_PHOTOS = 14,
	INJURED_VEH_DAMAGE_PHOTOS = 15,
	INJURED_VEH_PANORAMIC = 16,
	INJURED_OTHERS_DOCS = 17,
	VEHICLE_VIDEO = 19,
	INSURED_VEHICLE_VIDEO = 84,
}

export enum Brands {
	SURA = 'sura',
	CONNECT = 'connect',
	CSM = 'csm',
	LAFISE = 'lafise',
	OCEANICA = 'oceanica',
	QUALITAS = 'qualitas',
	ASSA = 'assa',
	SEGUROS_MUNDIAL = 'segurosmundial',
	MULTINATIONAL = 'multinational',
	PREMIER = 'premier',
	COMFENALCO = 'comfenalco',
}

export interface Action<T = any> {
	type: ActionType;
	data?: T;
}

export enum Validation {
	HAS_DOCUMENT = 'HAS_DOCUMENT',
	HAS_VEHICLE = 'HAS_VEHICLE',
	EXTRACT_PLATE = 'EXTRACT_PLATE',
	EXTRACT_VIN = 'EXTRACT_VIN',
	HAS_LEGIBLE_LICENSE = 'HAS_LEGIBLE_LICENSE',
}

export interface PhotoSections {
	description: string;
	srcImage: any;
	sectionId: string;
	photosIds: string[];
	required?: boolean;
}
interface SimplePhoto {
	dataUrl?: string;
	awsUrl?: string;
}

export interface Photo extends SimplePhoto {
	id: string; // TODO: Change to PhotoId
	section: PhotoSection;
	deleted?: boolean;
	placeholder?: string;
	selected?: boolean;
	description: string;
	required: boolean;
	sectionId: string;
	validations: Validation[]; // the AI validations we should run for this picture
	validationResults: {
		// the results from Heimdall as they arrive
		[Validation.HAS_VEHICLE]?: boolean;
		[Validation.HAS_DOCUMENT]?: boolean;
		[Validation.EXTRACT_PLATE]?: string;
		[Validation.EXTRACT_VIN]?: string;
	};
	useGallery?: boolean;
	name?: string;
}

// ===== Individuals (insured and counterpart for now)
export enum IndividualStateKey {
	INSURED = 'insured',
	COUNTERPART = 'counterpart',
	INJURED = 'injured',
}

export enum FieldStateKey {
	NAME = 'name',
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	EMAIL = 'email',
	PLATE = 'plate',
	MAKE = 'make',
	MODEL = 'model',
	YEAR = 'year',
	COLOR = 'color',
	PHONE = 'phone',
	SEX = 'sex',
	LICENSE = 'license',
	POSTAL_CODE = 'postalCode',
	URBANIZATION = 'urbanization',
	IS_OTHER_DRIVER = 'isOtherDriver',
	DRIVER_NAME = 'driverName',
	DRIVER_LASTNAME = 'driverLastName',
	DRIVER_PHONE = 'driverPhone',
	DRIVER_EMAIL = 'driverEmail',
	DRIVER_SEX = 'driverSex',
	DRIVER_LICENSE = 'driverLicense',
	DRIVER_POSTALCODE = 'driverPostalCode',
	DRIVER_URBANIZATION = 'driverUrbanization',
	DRIVER_MARBETE = 'driverMarbete',
	DRIVER_MILEAGE = 'driverMileage',
	RELATIONSHIP = 'driverRelationship',
	INCIDENT = 'incidentType',
	VIN = 'vin',
}

export enum BreadcrumbSteps {
	INSURED = 1,
	COUNTERPART = 2,
	INJURED = 3,
	DRIVER = 4,
	VEHICLE = 5,
	PHOTO_MENU = 6,
	PHOTOS = 7,
	SUMMARY = 8,
	SELECT = 9,
	SURVEY = 10,
}

export enum ScreenIndex {
	INJURED = 1,
	INSURED = 0,
	DRIVER = 2,
	VEHICLE = 3,
	INJURED_NARRATION = 4,
	SELECT_LOCATION = 5,
	LOCATION = 6,
	SCENE = 7,
	SCENE_PHOTO = 8,
	SELECT_SKETCH = 9,
	SKETCH = 10,
	INSURED_NARRATION = 11,
	RESPONSIBILITY = 12,
	INSURED_DAMAGES = 13,
	SELECT_ACCIDENT = 14,
	COUNTERPART_INFO = 15,
	COUNTERPART_DAMAGES = 16,
	COUNTERPART_LOCATION = 17,
	PHOTO_MENU = 18,
	PHOTOS = 19,
	SELECT_DEPOSIT = 20,
	SUMMARY = 21,
}

export const ScreenIndexCSM = {
	INJURED: 1,
	DRIVER: 2,
	VEHICLE: 3,
	INJURED_NARRATION: 4,
	INSURED_DAMAGES: 5,
	COUNTERPART_LOCATION: 6,
	LOCATION: 7,
	SCENE: 8,
	SKETCH: 9,
	PHOTO_MENU: 10,
	IS_DEPOSIT: 11,
	DEPOSIT_FORM: 12,
	SUMMARY: 13,
	SURVEY: 14,
};

export const ScreenIndexSura = {
	INSURED: 1,
	DRIVER: 2,
	VEHICLE: 3,
	LOCATION_SELECT: 4,
	LOCATION: 5,
	SCENE: 6,
	SKETCH: 7,
	INSURED_NARRATION: 8,
	INSURED_RESPONSABILITY: 9,
	INSURED_DAMAGES: 10,
	COUNTERPART_INFO: 11,
	COUNTERPART_DAMAGES: 12,
	PHOTO_MENU: 13,
	SUMMARY: 14,
	SURVEY: 15,
};

export const ScreenIndexCRC = {
	INSURED: 1,
	DRIVER: 2,
	VEHICLE: 3,
	LOCATION_SELECT: 4,
	IS_VEHICLE: 5,
	COUNTERPART_INFO: 6,
	POLICIVE_PART: 7,
	LOCATION: 8,
	SCENE: 9,
	SKETCH: 10,
	INSURED_NARRATION: 11,
	INSURED_ALERT: 12,
	INSURED_DAMAGES: 13,
	PHOTO_MENU: 14,
	UPLOAD_PHOTO: 15,
	SUMMARY: 16,
	SURVEY: 17,
};

export const ScreenIndexQualitas = {
	INSURED: 1,
	DRIVER: 2,
	VEHICLE: 3,
	LOCATION_SELECT: 4,
	IS_VEHICLE: 5,
	COUNTERPART_INFO: 6,
	POLICIVE_PART: 7,
	LOCATION: 8,
	INSURED_NARRATION: 11,
	INSURED_ALERT: 12,
	INSURED_DAMAGES: 13,
	PHOTO_MENU: 14,
	UPLOAD_PHOTO: 15,
	SUMMARY: 16,
	SURVEY: 17,
};

export const ScreenIndexWithoutSketchCRC = {
	INSURED: 1,
	DRIVER: 2,
	VEHICLE: 3,
	LOCATION_SELECT: 4,
	IS_VEHICLE: 5,
	COUNTERPART_INFO: 6,
	POLICIVE_PART: 7,
	LOCATION: 8,
	INSURED_NARRATION: 9,
	INSURED_ALERT: 10,
	INSURED_DAMAGES: 11,
	PHOTO_MENU: 12,
	UPLOAD_PHOTO: 13,
	SUMMARY: 14,
	SURVEY: 15,
};

export const ScreenIndexSegurosMundial = {
	INSURED: 1,
	DRIVER: 2,
	VEHICLE: 3,
	LOCATION_SELECT: 4,
	LOCATION: 5,
	SCENE: 6,
	SKETCH: 7,
	INSURED_NARRATION: 8,
	INSURED_DAMAGES: 9,
	PHOTO_MENU: 10,
	SUMMARY: 11,
	SURVEY: 12,
};

export const ScreenIndexMultinational = {
	INJURED: 1,
	DRIVER: 2,
	VEHICLE: 3,
	INJURED_NARRATION: 4,
	INSURED_DAMAGES: 5,
	COUNTERPART_LOCATION: 6,
	LOCATION: 7,
	SCENE: 8,
	SKETCH: 9,
	PHOTO_MENU: 10,
	SUMMARY: 11,
	SURVEY: 12,
};

export const ScreenIndexPREMIER = {
	INJURED: 1,
	DRIVER: 2,
	VEHICLE: 3,
	INJURED_NARRATION: 4,
	INSURED_DAMAGES: 5,
	COUNTERPART_LOCATION: 6,
	LOCATION: 7,
	SCENE: 8,
	SKETCH: 9,
	PHOTO_MENU: 10,
	SUMMARY: 11,
	SURVEY: 12,
};

export enum LocationIndex {
	LOCATION_MAP = 1,
	SELECT_LOCATION = 2,
	INSPECT_SITE = 3,
	IS_VEHICLE = 4,
	FIXED_OBJECT = 10,
	INCIDENT_TYPE = 11,
	ACCIDENT_TYPE = 5,
	IS_DEPOSIT = 6,
	DIRECT_DEPOSIT = 7,
	OTHER_LOCATION = 8,
	SCENE = 9,
}

export enum FormType {
	FORM = 1,
	UNIQUE_SELECTION = 2,
}

export interface IndividualState {
	damages: { [key in number]: string[] }; // area number => damage labels
	fields: { [key in FieldStateKey]: string }; // form fields
}

export interface Insured extends IndividualState {
	narration: string;
	narrationAdditional: string;
	alertNarration?: string;
}

export interface Injured extends IndividualState {
	narration: string;
}

// ===== Location
interface Location {
	coords: { lat: number; lng: number };
	mapSize: { height: number | null; width: number | null };
	address: string;
	newLocation?: boolean;
	selectedLocation?: string | null;
	originalCoords?: { lat: number; lng: number };
	originalAddress?: string;
	isGPSActive?: boolean | null;
	isManualLocation?: boolean | null;
	level1: string;
	level2: string;
	level3: string;
	customLevel1: string;
	customLevel2: string;
	customLevel3: string;
	date: Date | null;
	time: Date | null;
	isSelected?: boolean;
}

export interface LevelConfig {
	labelLevel1: string;
	labelLevel2: string;
	labelLevel3: string;
	levels: string;
}

export interface Option {
	id: string;
	name: string;
}

export interface SelectDataLocation {
	name: string;
	text: string;
	selected: boolean;
	disclaimer?: string;
}

// ===== Summary
// nulls mean not selected
interface Responsibility {
	responsible: Responsible | null;
	legal: boolean | null;
	insuredInitials: string;
}

export enum Responsible {
	COUNTERPART = 'CONTRAPARTE',
	INSURED = 'ASEGURADO',
	NONE = 'NINGUNO',
}

export interface IconPosition {
	x?: number;
	y?: number;
	rotateX?: number;
	rotateY?: number;
	rotateZ?: number;
	zoom?: number;
	scale?: number;
}
export interface IconProps {
	iconType: string;
	position: IconPosition;
	className: string;
}
export interface IconStatus {
	id: number;
	isSelected: boolean | undefined;
}

export interface AppContext {
	userSessionId: string;
	initSectionTime: number;
	dispatch: DispatchFunction;
	eventId: number | null; // null b.c. data may not be loaded.
	serviceId: string | null; // null b.c. data may not be loaded.
	accountId: string | null; // null b.c. data may not be loaded.
	account: string | null;
	countryCode: string | null;
	inspectionId: string | null; // null b.c. data may not be loaded.
	inspectionDate: Date | null;
	location: Location;
	insured: Insured;
	injured: Injured;
	photos: Photo[];
	allPhotos: Photo[];
	galleryPhotos: string[];
	counterpart: IndividualState;
	responsibility: Responsibility;
	sketch: {
		dataUrl: string | null;
		html: string | null;
		icons: IconProps[];
		infoWindowShown: boolean;
		rotateIcon: boolean;
		iconStatus: IconStatus[];
		iconId: number | null;
		scenePhoto: string | null;
		sceneType: string | null;
		draw: { dataUrl: string; points: string };
	};
	signature: string | null;
	status: number;
	accidentDate: string | null;
	accidentInfo: any | null;
	selectedAccidentType: string | null;
	typeIsVehicle?: string | null;
	selectedIsVehicle: boolean | null;
	selectedIsFixedObject: boolean | null;
	typePolicivePart?: string;
	policivePart: boolean | null;
	numberPart: string;
	selectedIsDeposit: boolean | null;
	directDeposit: DirectDeposit;
	photoSections: { [key: string]: { visited: boolean } };
	showInfoPhotos: boolean;
	incidentType: number | null;
	ClaimantType?: string;
	vin?: string;
}

export interface SelectedData {
	id: string;
	name: string;
}

export interface DirectDeposit {
	claimantName: string;
	claimantNumber: string;
	policy: string;
	financialInstitution: string;
	routeNumber: string;
	branchOffice: string;
	personalContact: string;
	phone: string;
	accountType: string;
	signature: string;
}

export type DispatchFunction = (action: Action) => void;

export interface SectionOptions {
	[key: string]: PhotoSections[];
}
