import { Photo, PhotoSection, Validation, PhotoSections } from '../../../context/interfaces';
import { ReactComponent as DamageIcon } from 'assets/icons/damage.svg';
import { ReactComponent as CarFrontSide } from 'assets/icons/car_front.svg';
import { ReactComponent as InternalPhoto } from 'assets/icons/internal-photo.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/ruc.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/i-360video.svg';
import {
	carPassengerSide,
	carDriverSide,
	carBack,
	carFront,
	plate,
	damage,
	license,
	vehicleRegistration,
	other,
	label,
	odometer,
	fixedObject,
	panoramic,
} from '../../../context/Utils';
import { PhotoId } from '@connect-technology/connect-react-components-lib';

export const qualitasPhotos: Photo[] = [
	{
		id: PhotoId.PANORAMIC,
		section: PhotoSection.PANORAMIC,
		placeholder: panoramic,
		selected: false,
		description: 'Panorámica',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'vehicle',
	},
	{
		id: PhotoId.INSURED_VEHICLE_PASSENGER_SIDE,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: carPassengerSide,
		selected: true,
		description: 'Foto Lateral Derecha del Vehículo de Asegurado',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'vehicle',
	},
	{
		id: PhotoId.INSURED_VEHICLE_DRIVER_SIDE,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: carDriverSide,
		selected: false,
		description: 'Foto Lateral Izquierda del Vehículo de Asegurado',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'vehicle',
	},
	{
		id: PhotoId.INSURED_VEHICLE_BACK,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: carBack,
		selected: false,
		description: 'Foto Trasera del Vehículo de Asegurado',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'vehicle',
	},
	{
		id: PhotoId.INSURED_VEHICLE_FRONT,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: carFront,
		selected: false,
		description: 'Foto Delantera del Vehículo de Asegurado',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'vehicle',
	},
	{
		id: PhotoId.INSURED_VEHICLE_PLATE_CR,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: plate,
		selected: false,
		description: 'Foto de Placa del Vehículo de Asegurado',
		required: true,
		validations: [Validation.EXTRACT_PLATE],
		validationResults: {},
		sectionId: 'vehicle',
	},
	{
		id: PhotoId.INSURED_VEHICLE_ODOMETER,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: odometer,
		selected: false,
		description: 'Odómetro',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'vehicle',
	},
	{
		id: PhotoId.INSURED_VEHICLE_DAMAGE_1,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: damage,
		selected: true,
		description: 'Foto de Daños 1 del Vehículo de Asegurado',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'damage',
	},
	{
		id: PhotoId.INSURED_VEHICLE_DAMAGE_2,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: damage,
		selected: false,
		description: 'Foto de Daños 2 del Vehículo de Asegurado',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'damage',
	},
	{
		id: PhotoId.INSURED_VEHICLE_DAMAGE_3,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: damage,
		selected: false,
		description: 'Foto de Daños 3 del Vehículo de Asegurado',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'damage',
	},
	{
		id: PhotoId.INSURED_VEHICLE_DAMAGE_4,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: damage,
		selected: false,
		description: 'Foto de Daños 4 del Vehículo de Asegurado',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'damage',
	},
	{
		id: PhotoId.FIXED_OBJECT,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: fixedObject,
		selected: false,
		description: 'Objeto Fijo',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'damage',
	},
	{
		id: PhotoId.INSURED_LICENSE_CR,
		section: PhotoSection.INSURED_DOCUMENTS,
		placeholder: license,
		selected: true,
		description: 'Foto de Licencia del Asegurado',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: PhotoId.COUNTERPART_CEDULA_CR,
		section: PhotoSection.INSURED_DOCUMENTS,
		placeholder: license,
		selected: true,
		description: 'Foto del frente de la cédula',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: PhotoId.CEDULA_BACK,
		section: PhotoSection.INSURED_DOCUMENTS,
		placeholder: license,
		selected: true,
		description: 'Foto del reverso de la cédula',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: PhotoId.INSURED_TECHNICAL_REVIEW,
		section: PhotoSection.INSURED_DOCUMENTS,
		placeholder: vehicleRegistration,
		selected: false,
		description: 'Foto Dekra',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: PhotoId.INSURED_CIRCULATION_RIGHTS,
		section: PhotoSection.INSURED_DOCUMENTS,
		placeholder: label,
		selected: false,
		description: 'Foto Marchamo',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: PhotoId.OTHERS_1,
		section: PhotoSection.OTHERS,
		placeholder: other,
		selected: true,
		description: 'Otros 1',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'others',
	},
	{
		id: PhotoId.OTHERS_2,
		section: PhotoSection.OTHERS,
		placeholder: other,
		selected: false,
		description: 'Otros 2',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'others',
	},
	{
		id: 'vehicle_video',
		section: PhotoSection.VEHICLE_VIDEO,
		selected: false,
		description: 'Video',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'video',
	},
];

export const qualitasPhotoSections: PhotoSections[] = [
	{
		description: 'Mi Auto',
		srcImage: CarFrontSide,
		sectionId: 'vehicle',
		photosIds: [
			PhotoId.INSURED_VEHICLE_PASSENGER_SIDE,
			PhotoId.INSURED_VEHICLE_DRIVER_SIDE,
			PhotoId.INSURED_VEHICLE_BACK,
			PhotoId.INSURED_VEHICLE_FRONT,
			PhotoId.INSURED_VEHICLE_PLATE_CR,
			PhotoId.INSURED_VEHICLE_ODOMETER,
			PhotoId.PANORAMIC,
		],
		required: true,
	},
	{
		description: 'Daños del Vehículo',
		srcImage: DamageIcon,
		sectionId: 'damage',
		photosIds: [
			PhotoId.INSURED_VEHICLE_DAMAGE_1,
			PhotoId.INSURED_VEHICLE_DAMAGE_2,
			PhotoId.INSURED_VEHICLE_DAMAGE_3,
			PhotoId.INSURED_VEHICLE_DAMAGE_4,
			PhotoId.FIXED_OBJECT,
		],
		required: true,
	},
	{
		description: 'Mis Documentos',
		srcImage: DocumentIcon,
		sectionId: 'documents',
		photosIds: [
			PhotoId.COUNTERPART_CEDULA_CR,
			PhotoId.CEDULA_BACK,
			PhotoId.INSURED_LICENSE_CR,
			PhotoId.INSURED_POLICIVE_PART_CR,
			PhotoId.INSURED_TECHNICAL_REVIEW,
		],
		required: true,
	},
	{
		description: 'Otros (Opcional)',
		srcImage: InternalPhoto,
		sectionId: 'others',
		photosIds: [PhotoId.OTHERS_1, PhotoId.OTHERS_2],
		required: false,
	},
	{
		description: 'Video (Opcional)',
		srcImage: VideoIcon,
		sectionId: 'video',
		photosIds: [],
		required: false,
	},
];
