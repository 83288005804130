import { Rating } from '@material-ui/lab';
import React from 'react';
import { FunctionComponent } from 'react';
import { ReactComponent as Survey99 } from 'assets/icons/99_VI.svg';

interface DefaultProps {
	setRecommend: (data: number | null) => void;
	recommend: number | null;
}

export const DefaultSurvey: FunctionComponent<DefaultProps> = ({
	setRecommend,
	recommend,
}: DefaultProps): JSX.Element => {
	return (
		<>
			<hr className="section-divider" />
			<div className="question">
				<div className="question__rate">
					<p className="question__text">¿Cuán probable es que nos recomiende a un amigo o familiar?</p>
					<Rating
						name="recommend"
						value={recommend}
						size="large"
						onChange={(event: React.ChangeEvent<{}>, newValue: number | null) => {
							setRecommend(newValue);
						}}
					/>
				</div>
				<div className="question__image">
					<Survey99 />
				</div>
			</div>
		</>
	);
};

export default DefaultSurvey;
