import React, { HTMLAttributes } from 'react';
import './styles.scss';

interface Option {
	id: string;
	name: string;
}
interface Props extends React.DetailedHTMLProps<HTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
	label: string;
	value?: string;
}

const TextArea = (props: Props): JSX.Element => {
	return (
		<div className="textArea">
			<label className="textArea__label">{props.label}</label>
			<textarea className="textArea__field" rows={4} value={props.value} onChange={props.onChange}></textarea>
		</div>
	);
};

export default TextArea;
