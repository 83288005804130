import React, { useContext, useState, useEffect } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import Header from '../../components/common/Header';
import { Context } from '../../context/Context';
import { AppContext, LocationIndex, ActionType } from '../../context/interfaces';
import ProgressBar from '../../components/ProgressBar';
import SceneOptions from '../../components/scene/scene-options';
import { SCENES } from '../../static/constants/scenes';
import { ChunkArray } from '../../shared/utils/array-helpers';
import { completeSectionGTM, initializeSectionGTM } from '../../shared/utils/gooogleTagManager';
import { useHistory, useParams } from 'react-router';

import './styles.scss';
import { Scene } from '../../types/scene';
import { urlIdRewrite } from 'shared/utils';

interface Props {
	locationIndex: LocationIndex; // set location
	screenIndex: any;
}

const SceneScreen = (props: Props): JSX.Element => {
	const [selectedScene, setSelectedScene] = useState<string>('');
	const [scenesOptionsRows, setScenesOptionsRows] = useState<Array<any>>([]);
	const locationPath = useLocation();
	const history = useHistory();
	const ctx = useContext(Context) as AppContext;
	const { id } = useParams<{ id: string }>();
	const [initLoadGT, setInitLoadGT] = useState(true);
	const { location, dispatch } = ctx;
	const canContinue = selectedScene;

	useEffect(() => {
		if (initLoadGT) {
			initializeSectionGTM(ctx, locationPath.pathname);
			setInitLoadGT(false);
		}
	}, [initLoadGT, ctx, locationPath.pathname]);

	useEffect(() => {
		const optionsToRender = [...SCENES];
		if (!location.isGPSActive) {
			optionsToRender.shift();
		}
		setScenesOptionsRows(ChunkArray(optionsToRender, 2));
	}, [location.isGPSActive, ctx, locationPath.pathname]);

	const handleConfirm = (): void => {
		if (selectedScene) {
			const objOfSelectedScene = SCENES.find((scene) => scene.value === selectedScene) as Scene;
			if (objOfSelectedScene.currentLocation) {
				dispatch({
					type: ActionType.SET_SCENE_PHOTO,
					data: null,
				});
			}
			completeSectionGTM(ctx, locationPath.pathname);
			history.push(urlIdRewrite(objOfSelectedScene.to, id));
		}
	};

	return (
		<div className="sceneScreen">
			<Breadcrumbs currentStep={props.screenIndex} />
			<ProgressBar screenIndex={props.screenIndex} />

			<Header title="Selecciona el tipo de escenario donde ocurrió el accidente" />

			<div className="sceneScreen__options">
				<SceneOptions
					selectedScene={selectedScene}
					scenesOptionsRows={scenesOptionsRows}
					onClickScene={(value) => setSelectedScene(value)}
				/>
			</div>

			<button
				className={clsx('confirm-button', { disabled: !canContinue })}
				disabled={!canContinue}
				onClick={handleConfirm}
			>
				Continuar
			</button>
		</div>
	);
};

export default SceneScreen;
