// Initalize Image Tool (loaded at index.html)
declare global {
	interface Window {
		measureBlur: any;
	}
}

const getDimensionData = (img: any, refCanvas: any, refContext: any): any => {
	// Set a width and height constant value for the validation has considerate the edge analysis
	let defineW = 0;
	let defineH = 0;
	if (img.width > img.height) {
		// Detect is landscape
		console.log('');
		defineW = 1280;
		defineH = 720;
	} else if (img.width < img.height) {
		// Detect is portrait
		defineW = 720;
		defineH = 1280;
	} else {
		// Is Equals
		defineW = 1280;
		defineH = 1280;
	}

	const oc = document.createElement('canvas');
	const octx = oc.getContext('2d');
	// Set the width & height
	oc.width = defineW;
	oc.height = defineH;

	if (octx) {
		// step 2, resize to temporary size
		octx.drawImage(img.data, 0, 0, oc.width, oc.height);
	}

	refCanvas.width = defineW; //img.width;
	refCanvas.height = defineH; //img.height;

	refContext.drawImage(oc, 0, 0, defineW, defineH, 0, 0, defineW, defineH);

	return refContext.getImageData(0, 0, defineW, defineH);
};

export const isBlurryImg = async (fileData: File): Promise<boolean> => {
	return await new Promise((resolve) => {
		const isEnable = process.env.REACT_APP_BLURRY_FEATURE_FLAG ?? 'FALSE';

		if (isEnable === 'FALSE') {
			resolve(false);
		} else {
			//Note: All percentages the scan edges major to 0.95 is tagged a blurry image.
			const toleranceValue = process.env.REACT_APP_BLURRY_FEATURE_TOLERANCE
				? +process.env.REACT_APP_BLURRY_FEATURE_TOLERANCE
				: 1;

			const readImageFile = (rawFile: File) => {
				return new Promise(function (resolve, reject) {
					if (!rawFile) {
						return reject();
					}

					const reader = new FileReader();
					reader.onload = function (readerEvent) {
						const img = new Image();
						img.onload = function () {
							resolve({
								// NOTE: This is not ImageData object!
								rawFile: rawFile,
								data: img,
								width: img.width,
								height: img.height,
							});
						};
						img.onerror = reject;
						img.src =
							readerEvent &&
							readerEvent.target &&
							readerEvent.target.result !== null &&
							typeof readerEvent.target.result === 'string'
								? readerEvent.target.result
								: '';
					};
					reader.readAsDataURL(rawFile);
				});
			};

			const done = (img: any) => {
				const canvas = document.createElement('canvas') as HTMLCanvasElement;
				const context = canvas.getContext('2d');

				if (img.data && context) {
					const resultImageData = getDimensionData(img, canvas, context);
					const validationResult = window.measureBlur(resultImageData);
					canvas.remove();

					if (validationResult && validationResult.avg_edge_width_perc > 0) {
						// eslint-disable-next-line no-console
						console.log(
							'IS_BLURRY::',
							validationResult.avg_edge_width_perc.toFixed(2) > toleranceValue,
							'::EDGE::',
							String(validationResult.avg_edge_width_perc.toFixed(2)),
							'::TOLERANCE::',
							String(toleranceValue)
						);
						resolve(validationResult.avg_edge_width_perc.toFixed(2) > toleranceValue);
					} else {
						resolve(false);
					}
				}
			};

			readImageFile(fileData).then(done, console.error);
		}
	});
};
