import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { IndividualStateKey, Brands, ScreenIndex, LocationIndex } from '../context/interfaces';
import activeGuard from './ActiveGuard';
import DamagesScreen from './damages/Damages';
import NarrationScreen from './insured/Narration';
import SketchScreen from './insured/Sketch';
import LocationScreen from './location/Location';
import CameraScreen from './photos/CameraScreen';
import ClientInfoScreen from './policy/ClientInfo';
import SplashScreen from './splash/Splash';
import SuccessScreen from './success/Success';
import ResponsibilityScreen from './summary/Responsibility';
import SummaryScreen from './summary/Summary';
import RightsFormScreen from './rightsForm';
import InsuranceAlertScreen from './insured/InsuranceAlert';
import IsVehicleScreen from './isVehicle/IsVehicle';
import PolicivePartScreen from './summary/PolicivePart';
import UploadPhotoScreen from './photos/UploadPhoto';
import InactiveScreen from './inactive/Inactive';
import IsDepositScreen from './deposit/IsDeposit';
import DirectDepositScreen from './deposit/DirectDeposit';
import SceneScreen from './scene';
import ScenePhotosScreen from './scene/scene-photos';
import PhotoSectionsMenu from './photos/PhotoSectionsMenu/PhotoSectionsMenu';
import { PARKING_SCENES, ROAD_SCENES } from '../static/constants/scenes';
import { indexByBrand } from 'shared/utils';
import { BRAND } from 'shared/utils';
import Survey from './survey/Survey';
import VideoUploadScreen from './photos/video/VideoUploadScreen';
import AlertDeviceScreen from '../screens/alertDevice/AlertDeviceScreen';
import PATH_ROUTES from '../static/constants/path-routes';
import StoryScreen from './insured/story';
import { usePendoInitialization } from 'pendo/pendoUtils';

// Used to render a white screen before validating guard.
const RENDER_BLANK = true;

const ScreensRoot = (): JSX.Element => {
	usePendoInitialization();

	const title =
		Brands.CSM === BRAND || Brands.PREMIER === BRAND || Brands.MULTINATIONAL === BRAND
			? 'Valide Información de Perjudicado'
			: 'Valide Información Asegurado';
	return (
		<Switch>
			{/* COMMON path routes */}
			<Route
				path="/:id/insured/information"
				key="/insured/information" // key is used to remount the component
				render={(): JSX.Element =>
					activeGuard(
						<ClientInfoScreen screenKey={indexByBrand.insuredInformation} title={title} to="/:id/driver/information" />
					)
				}
			/>
			<Route
				path="/:id/driver/information"
				key="/driver/information" // key is used to remount the component
				render={(): JSX.Element =>
					activeGuard(
						<ClientInfoScreen
							screenKey={indexByBrand.driverInformation}
							title={
								[Brands.LAFISE, Brands.OCEANICA, Brands.QUALITAS].includes(BRAND as Brands)
									? 'Datos del Conductor'
									: 'Valide información del Conductor'
							}
							to="/:id/vehicle/information"
						/>
					)
				}
			/>
			<Route
				path="/:id/vehicle/information"
				key="/vehicle/information" // key is used to remount the component
				render={(): JSX.Element =>
					activeGuard(
						<ClientInfoScreen
							screenKey={indexByBrand.vehicleInformation}
							title={
								BRAND === Brands.CSM || BRAND === Brands.MULTINATIONAL || Brands.PREMIER === BRAND
									? 'Valide vehículo del Perjudicado'
									: 'Valide vehículo del asegurado'
							}
							to={
								BRAND === Brands.CSM || BRAND === Brands.MULTINATIONAL || Brands.PREMIER === BRAND
									? '/:id/injured/narration'
									: '/:id/location/select'
							}
						/>
					)
				}
			/>
			<Route
				path="/:id/location/select"
				key="/location/select"
				render={(): JSX.Element => {
					const toPage = [Brands.ASSA, Brands.LAFISE, Brands.OCEANICA, Brands.QUALITAS, Brands.CONNECT].includes(
						BRAND as Brands
					)
						? `/:id/${BRAND}/isVehicle`
						: '/:id/location';
					return activeGuard(
						<LocationScreen
							locationIndex={LocationIndex.INSPECT_SITE}
							to={toPage}
							screenIndex={indexByBrand.locationSelect}
						/>
					);
				}}
			/>
			<Route
				path="/:id/location"
				key="/location"
				render={(): JSX.Element => {
					const toPage = [Brands.ASSA, Brands.LAFISE, Brands.OCEANICA].includes(BRAND as Brands)
						? '/:id/insured/narration'
						: '/:id/scene';
					return activeGuard(
						<LocationScreen
							to={toPage}
							locationIndex={LocationIndex.LOCATION_MAP}
							screenIndex={indexByBrand.location}
						/>
					);
				}}
			/>
			<Route
				path="/:id/other-location"
				key="/other-location"
				render={(): JSX.Element =>
					activeGuard(
						<LocationScreen
							locationIndex={LocationIndex.OTHER_LOCATION}
							to="/:id/scene"
							screenIndex={indexByBrand.location}
						/>
					)
				}
			/>
			<Route
				path="/:id/counterpart/information"
				key="/counterpart/information"
				render={(): JSX.Element =>
					activeGuard(
						<ClientInfoScreen
							screenKey={indexByBrand.counterPartInformation}
							isCounterpartInfo
							title="Información de vehículo de la contraparte"
							to={
								[Brands.LAFISE, Brands.OCEANICA, Brands.QUALITAS, Brands.ASSA, Brands.CONNECT].includes(BRAND as Brands)
									? '/:id/policivePart'
									: '/:id/counterpart/damages'
							}
						/>
					)
				}
			/>
			<Route
				path="/:id/counterpart/damages"
				key="/counterpart/damages"
				render={(): JSX.Element =>
					activeGuard(
						<DamagesScreen title="Contraparte" screenKey={indexByBrand.counterPartDamages} to="/:id/photo/sections" />
					)
				}
			/>
			<Route
				path="/:id/insured/sketch"
				key="/insured/sketch"
				render={(): JSX.Element =>
					activeGuard(
						<SketchScreen
							to={
								BRAND === Brands.CSM || BRAND === Brands.MULTINATIONAL || Brands.PREMIER === BRAND
									? '/:id/injured/narration'
									: '/:id/insured/narration'
							}
							screenIndex={indexByBrand.sceneLocation}
						/>
					)
				}
			/>
			<Route
				path="/:id/insured/narration"
				key="/insured/narration"
				render={(): JSX.Element =>
					activeGuard(
						<NarrationScreen
							to={
								BRAND === Brands.LAFISE
									? '/:id/lafise/insured/insurance-alert'
									: BRAND === Brands.SURA
									? '/:id/insured/responsibility'
									: BRAND === Brands.OCEANICA
									? '/:id/oceanica/insured/insurance-alert'
									: '/:id/insured/damages'
							}
							screenKey={indexByBrand.insuredNarration}
							stateKey={IndividualStateKey.INSURED}
						/>
					)
				}
			/>
			<Route
				path="/:id/insured/story"
				key="/insured/story"
				render={(): JSX.Element =>
					activeGuard(
						<StoryScreen
							to={'/:id/qualitas/insured/insurance-alert'}
							screenKey={indexByBrand.insuredNarration}
							stateKey={IndividualStateKey.INSURED}
						/>
					)
				}
			/>
			<Route
				path="/:id/insured/damages"
				key="/insured/damages"
				render={(): JSX.Element =>
					activeGuard(
						<DamagesScreen
							title="Asegurado"
							screenKey={indexByBrand.insuredDamages}
							to={
								[
									Brands.CONNECT,
									Brands.LAFISE,
									Brands.OCEANICA,
									Brands.QUALITAS,
									Brands.ASSA,
									Brands.SEGUROS_MUNDIAL,
									Brands.COMFENALCO,
								].includes(BRAND as Brands)
									? '/:id/photo/sections'
									: '/:id/counterpart/information'
							}
						/>
					)
				}
			/>

			<Route
				path="/:id/policivePart"
				key="/policivePart"
				render={(): JSX.Element => {
					const to = [Brands.QUALITAS, Brands.LAFISE, Brands.OCEANICA].includes(BRAND as Brands)
						? `/:id/${BRAND}/location`
						: '/:id/location';

					return activeGuard(<PolicivePartScreen to={to} screenIndex={indexByBrand.policivePart} />);
				}}
			/>

			<Route
				path="/:id/photos/camera"
				key="/photos/camera"
				render={(): JSX.Element => activeGuard(<CameraScreen screenIndex={0} />)}
			/>
			<Route
				path="/:id/camera/video"
				key="/camera/video"
				render={(): JSX.Element => activeGuard(<VideoUploadScreen />)}
			/>
			<Route
				path="/:id/summary"
				key="/summary"
				render={(): JSX.Element =>
					activeGuard(
						<SummaryScreen
							to={
								[Brands.CSM, Brands.SEGUROS_MUNDIAL, Brands.MULTINATIONAL, Brands.PREMIER, Brands.COMFENALCO].includes(
									BRAND as Brands
								)
									? '/:id/rights-form'
									: '/:id/survey'
							}
							screenIndex={indexByBrand.summary}
						/>
					)
				}
			/>
			{/* /success doesn't use activeGuard */}
			<Route path="/:id/success" key="/success" component={SuccessScreen} />

			{/* CONNECT / SURA path routes */}
			<Route
				path="/:id/sura/insured/information"
				key="/sura/insured/information" // key is used to remount the component
				render={(): JSX.Element =>
					activeGuard(<ClientInfoScreen screenKey={ScreenIndex.INSURED} title={title} to="/:id/location" />)
				}
			/>

			<Route
				path="/:id/insured/responsibility"
				key="/insured/responsibility"
				render={(): JSX.Element =>
					activeGuard(
						<ResponsibilityScreen
							to={
								[Brands.LAFISE, Brands.OCEANICA, Brands.QUALITAS].includes(BRAND as Brands)
									? '/:id/photos/camera'
									: '/:id/insured/damages'
							}
							screenIndex={indexByBrand.insuredResponsability}
						/>
					)
				}
			/>

			<Route
				path="/:id/scene/location"
				key="/scene/location"
				render={(): JSX.Element =>
					activeGuard(
						<SketchScreen
							to={
								BRAND === Brands.CSM || BRAND === Brands.MULTINATIONAL || BRAND === Brands.PREMIER
									? '/:id/photo/sections'
									: '/:id/insured/narration'
							}
							screenIndex={indexByBrand.sceneLocation}
						/>
					)
				}
			/>

			<Route
				path="/:id/scene/parking"
				key="/scene/parking"
				render={(): JSX.Element =>
					activeGuard(<ScenePhotosScreen photos={PARKING_SCENES} screenIndex={indexByBrand.scene} />)
				}
			/>

			<Route
				path="/:id/scene/road"
				key="/scene/road"
				render={(): JSX.Element =>
					activeGuard(<ScenePhotosScreen photos={ROAD_SCENES} screenIndex={indexByBrand.scene} />)
				}
			/>
			<Route
				path="/:id/scene"
				key="/scene"
				render={(): JSX.Element =>
					activeGuard(<SceneScreen locationIndex={LocationIndex.SCENE} screenIndex={indexByBrand.scene} />)
				}
			/>

			{/* CSM path routes */}
			<Route
				path="/:id/injured/information"
				key="/injured/information" // key is used to remount the component
				render={(): JSX.Element =>
					activeGuard(
						<ClientInfoScreen screenKey={indexByBrand.injuredInformation} title={title} to="/:id/driver/information" />
					)
				}
			/>
			<Route
				path="/:id/injured/narration"
				key="/injured/narration"
				render={(): JSX.Element =>
					activeGuard(
						<NarrationScreen
							to="/:id/injured/damages"
							screenKey={indexByBrand.injuredNarration}
							stateKey={IndividualStateKey.INJURED}
						/>
					)
				}
			/>
			<Route
				path="/:id/injured/damages"
				key="/injured/damages"
				render={(): JSX.Element =>
					activeGuard(
						<DamagesScreen
							title="Perjudicado"
							screenKey={indexByBrand.injuredDamages}
							to="/:id/injured/location/select"
						/>
					)
				}
			/>
			<Route
				path="/:id/injured/location/select"
				key="/injured/location/select"
				render={(): JSX.Element =>
					activeGuard(
						<LocationScreen
							to="/:id/injured/location"
							locationIndex={LocationIndex.SELECT_LOCATION}
							screenIndex={indexByBrand.injuredLocationSelect}
						/>
					)
				}
			/>
			<Route
				path="/:id/injured/location"
				key="/injured/location"
				render={(): JSX.Element =>
					activeGuard(
						<LocationScreen
							to="/:id/scene"
							locationIndex={LocationIndex.LOCATION_MAP}
							screenIndex={indexByBrand.injuredLocation}
						/>
					)
				}
			/>
			<Route
				path="/:id/injured/sketch"
				key="/injured/sketch"
				render={(): JSX.Element =>
					activeGuard(<SketchScreen to="/:id/photo/sections" screenIndex={indexByBrand.sceneLocation} />)
				}
			/>
			<Route
				path="/:id/photo/sections"
				key="/photo/sections"
				render={(): JSX.Element =>
					activeGuard(
						<PhotoSectionsMenu
							to={
								BRAND === Brands.LAFISE
									? '/:id/lafise/uploadPhoto'
									: BRAND === Brands.ASSA
									? '/:id/assa/uploadPhoto'
									: BRAND === Brands.OCEANICA
									? '/:id/oceanica/uploadPhoto'
									: BRAND === Brands.QUALITAS
									? '/:id/qualitas/uploadPhoto'
									: BRAND === Brands.CONNECT
									? '/:id/connect/uploadPhoto'
									: '/:id/summary'
							}
							screenIndex={indexByBrand.photoSection}
						/>
					)
				}
			/>
			<Route
				path="/:id/injured/photos/camera"
				key="/injured/photos/camera"
				render={(): JSX.Element => activeGuard(<CameraScreen screenIndex={0} />)}
			/>
			<Route
				path="/:id/injured/deposit"
				key="/injured/deposit"
				render={(): JSX.Element =>
					activeGuard(
						<IsDepositScreen
							to="/:id/summary"
							locationIndex={LocationIndex.IS_DEPOSIT}
							screenIndex={indexByBrand.isDeposit}
						/>
					)
				}
			/>
			<Route
				path="/:id/deposit/form"
				key="/deposit/form"
				render={(): JSX.Element =>
					activeGuard(<DirectDepositScreen to="/:id/summary" screenIndex={indexByBrand.depositForm} />)
				}
			/>
			<Route
				path="/:id/rights-form"
				key="/rights-form"
				render={(): JSX.Element =>
					activeGuard(
						<RightsFormScreen
							to={
								[Brands.CSM, Brands.SEGUROS_MUNDIAL, Brands.MULTINATIONAL, Brands.PREMIER, Brands.COMFENALCO].includes(
									BRAND as Brands
								)
									? '/:id/survey'
									: '/:id/success'
							}
						/>
					)
				}
			/>

			<Route
				path="/:id/connect/isVehicle"
				key="/connect/isVehicle"
				render={(): JSX.Element =>
					activeGuard(
						<IsVehicleScreen
							to="/:id/counterpart/information"
							locationIndex={LocationIndex.IS_VEHICLE}
							screenIndex={indexByBrand.isVehicle}
						/>
					)
				}
			/>
			<Route
				path="/:id/connect/uploadPhoto"
				key="/connect/uploadPhoto"
				render={(): JSX.Element =>
					activeGuard(<UploadPhotoScreen to="/:id/summary" screenIndex={indexByBrand.uploadPhoto} />)
				}
			/>

			{/* LAFISE path routes */}
			<Route
				path="/:id/lafise/location"
				key="/lafise/location"
				render={(): JSX.Element =>
					activeGuard(
						<LocationScreen
							to="/:id/insured/narration"
							locationIndex={LocationIndex.LOCATION_MAP}
							screenIndex={indexByBrand.location}
						/>
					)
				}
			/>
			<Route
				path="/:id/lafise/insured/insurance-alert"
				key="/lafise/insured/insurance-alert"
				render={(): JSX.Element =>
					activeGuard(
						<InsuranceAlertScreen
							to="/:id/insured/damages"
							stateKey={IndividualStateKey.INSURED}
							screenKey={indexByBrand.insuranceAlert}
						/>
					)
				}
			/>
			<Route
				path="/:id/lafise/isVehicle"
				key="/lafise/isVehicle"
				render={(): JSX.Element =>
					activeGuard(
						<IsVehicleScreen
							to="/:id/counterpart/information"
							locationIndex={LocationIndex.IS_VEHICLE}
							screenIndex={indexByBrand.isVehicle}
						/>
					)
				}
			/>
			<Route
				path="/:id/lafise/uploadPhoto"
				key="/lafise/uploadPhoto"
				render={(): JSX.Element =>
					activeGuard(<UploadPhotoScreen to="/:id/summary" screenIndex={indexByBrand.uploadPhoto} />)
				}
			/>
			<Route
				path="/:id/assa/isVehicle"
				key="/assa/isVehicle"
				render={(): JSX.Element =>
					activeGuard(
						<IsVehicleScreen
							to="/:id/counterpart/information"
							locationIndex={LocationIndex.IS_VEHICLE}
							screenIndex={indexByBrand.isVehicle}
						/>
					)
				}
			/>
			<Route
				path="/:id/assa/uploadPhoto"
				key="/assa/uploadPhoto"
				render={(): JSX.Element =>
					activeGuard(<UploadPhotoScreen to="/:id/summary" screenIndex={indexByBrand.uploadPhoto} />)
				}
			/>

			{/* QUALITAS path routes */}
			<Route
				path="/:id/qualitas/location"
				key="/qualitas/location"
				render={(): JSX.Element =>
					activeGuard(
						<LocationScreen
							to="/:id/insured/story"
							locationIndex={LocationIndex.LOCATION_MAP}
							screenIndex={indexByBrand.location}
						/>
					)
				}
			/>
			<Route
				path="/:id/qualitas/insured/insurance-alert"
				key="/qualitas/insured/insurance-alert"
				render={(): JSX.Element =>
					activeGuard(
						<InsuranceAlertScreen
							to="/:id/insured/damages"
							stateKey={IndividualStateKey.INSURED}
							screenKey={indexByBrand.insuredAlert}
						/>
					)
				}
			/>
			<Route
				path="/:id/qualitas/isVehicle"
				key="/qualitas/isVehicle"
				render={(): JSX.Element =>
					activeGuard(
						<IsVehicleScreen
							to="/:id/counterpart/information"
							locationIndex={LocationIndex.IS_VEHICLE}
							screenIndex={indexByBrand.isVehicle}
						/>
					)
				}
			/>
			<Route
				path="/:id/qualitas/uploadPhoto"
				key="/qualitas/uploadPhoto"
				render={(): JSX.Element =>
					activeGuard(<UploadPhotoScreen to="/:id/summary" screenIndex={indexByBrand.uploadPhoto} />)
				}
			/>

			{/* OCEANICA path routes */}
			<Route
				path="/:id/oceanica/location"
				key="/oceanica/location"
				render={(): JSX.Element =>
					activeGuard(
						<LocationScreen
							to="/:id/insured/narration"
							locationIndex={LocationIndex.LOCATION_MAP}
							screenIndex={indexByBrand.location}
						/>
					)
				}
			/>
			<Route
				path="/:id/oceanica/insured/insurance-alert"
				key="/oceanica/insured/insurance-alert"
				render={(): JSX.Element =>
					activeGuard(
						<InsuranceAlertScreen
							to="/:id/insured/damages"
							stateKey={IndividualStateKey.INSURED}
							screenKey={indexByBrand.insuranceAlert}
						/>
					)
				}
			/>
			<Route
				path="/:id/oceanica/isVehicle"
				key="/oceanica/isVehicle"
				render={(): JSX.Element =>
					activeGuard(
						<IsVehicleScreen
							to="/:id/counterpart/information"
							locationIndex={LocationIndex.IS_VEHICLE}
							screenIndex={indexByBrand.isVehicle}
						/>
					)
				}
			/>
			<Route
				path="/:id/oceanica/uploadPhoto"
				key="/oceanica/uploadPhoto"
				render={(): JSX.Element =>
					activeGuard(<UploadPhotoScreen to="/:id/summary" screenIndex={indexByBrand.uploadPhoto} />)
				}
			/>
			<Route path="/:id/inactive" key="/inactive" component={InactiveScreen} />
			<Route path={PATH_ROUTES.PRE_ALERT_DEVICE_SCREEN} key="/alert" component={AlertDeviceScreen} />
			<Route
				path="/:id/survey"
				key="survey"
				render={(): JSX.Element => activeGuard(<Survey screenKey={indexByBrand.survey} to="/:id/success" />)}
			/>
			<Route path="/:id" key="/:id" render={(): JSX.Element => activeGuard(<SplashScreen />, RENDER_BLANK)} />
		</Switch>
	);
};

export default ScreensRoot;
