import React, { ReactElement } from 'react';
import Button from '../../components/common/Button';
import Portal from '../../components/portal';
import '../../assets/styles/components/camera/WarningScreen.scss';
import warningAlert from '../../assets/icons/warning_alert.png';

interface WarningProps {
	title: string;
	message: string;
	handleClick?: () => void;
	hideButton?: boolean;
	note?: string;
}

const Warning = ({ title, message, hideButton, note, handleClick = (): null => null }: WarningProps): ReactElement => {
	return (
		<Portal>
			<div className="warning">
				<div className="warning__container">
					<img src={warningAlert} className="warning--alert" alt="Warning Alert" />
					<h2>{title}</h2>
					<p>{message}</p>
					<p>{note}</p>
					{!hideButton && <Button onClick={handleClick}>Tomar nuevamente</Button>}
				</div>
			</div>
		</Portal>
	);
};

export default Warning;
