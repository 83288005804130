import React from 'react';
import { FunctionComponent } from 'react';
import { FormControlLabel, Mark, Radio, RadioGroup, Slider } from '@material-ui/core';

interface SMSurveyProps {
	handleChange: (data: string) => void;
	handleChangeRecommended: (data: number) => void;
	setInconvenient: (data: string) => void;
	inconvenient: string;
	setExperience: (data: number | null) => void;
	isVisible: boolean;
}

export const SMSurvey: FunctionComponent<SMSurveyProps> = ({
	handleChange,
	handleChangeRecommended,
	setInconvenient,
	inconvenient,
	setExperience,
	isVisible,
}: SMSurveyProps): JSX.Element => {
	const scaleSlider = (): Mark[] => {
		const result: Mark[] = [];

		for (let index = 0; index <= 10; index++) {
			result.push({
				value: index,
				label: String(index),
			});
		}

		return result;
	};

	function valuetext(value: number) {
		handleChangeRecommended(value);

		return `${value}`;
	}

	return (
		<>
			<div className="question">
				<div className="question__rate">
					<p className="question__text">
						¿Cómo calificarías el proceso a realizar mediante la plataforma de autogestión?
					</p>
					<RadioGroup name="controlled-radio-buttons-group">
						<FormControlLabel
							value="5"
							control={<Radio />}
							label="Muy fácil"
							onChange={() => setExperience(5)}
							color="primary"
						/>
						<FormControlLabel
							value="4"
							control={<Radio />}
							label="Fácil"
							onChange={() => setExperience(4)}
							color="primary"
						/>
						<FormControlLabel
							value="3"
							control={<Radio />}
							label="Ni fácil, ni difícil"
							onChange={() => setExperience(3)}
							color="primary"
						/>
						<FormControlLabel
							value="2"
							control={<Radio />}
							label="Difícil"
							onChange={() => setExperience(2)}
							color="primary"
						/>
						<FormControlLabel
							value="1"
							control={<Radio />}
							label="Muy difícil"
							onChange={() => setExperience(1)}
							color="primary"
						/>
					</RadioGroup>
				</div>
				<div className="question__image"></div>
			</div>
			<hr className="section-divider" />
			<div className="question--comment">
				<p className="question__text">
					¿Tuviste alguna novedad al momento de radicar tu siniestro mediante la plataforma de autogestión?:
				</p>

				<RadioGroup name="controlled-radio-buttons-group">
					<FormControlLabel
						value="Si"
						control={<Radio />}
						label="Si"
						onChange={() => handleChange('Si')}
						color="primary"
					/>
					<FormControlLabel
						value="No"
						control={<Radio />}
						label="No"
						onChange={() => handleChange('No')}
						color="primary"
					/>
				</RadioGroup>
			</div>
			<div hidden={isVisible}>
				<hr className="section-divider" />
				<div className="question--comment">
					<p className="question__text">¿Cuál fue la novedad que presentó?:</p>
					<textarea rows={5} value={inconvenient} onChange={(e) => setInconvenient(e.target.value)}></textarea>
				</div>
			</div>

			<hr className="section-divider" />
			<div className="question--comment">
				<p className="question__text">
					En una escala de 0 a 10 ¿En qué medida recomendarías a un amigo o familiar la plataforma de autogestión para
					radicar siniestros de Seguros Mundial?:
				</p>

				<Slider
					defaultValue={0}
					getAriaValueText={valuetext}
					aria-labelledby="discrete-slider-custom"
					min={0}
					step={1}
					max={10}
					valueLabelDisplay="auto"
					marks={scaleSlider()}
				/>
			</div>
		</>
	);
};

export default SMSurvey;
