import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/styles/index.scss';

Sentry.init({
	dsn: 'https://b76cdf9fc4164f079e03cf1cb71a24b7@o298640.ingest.sentry.io/5212488',
	environment: process.env.NODE_ENV,
	debug: process.env.NODE_ENV === 'development',
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
