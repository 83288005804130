import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import ConfirmButton from '../../components/common/ConfirmButton';
import Header from '../../components/common/Header';
import { Context } from '../../context/Context';
import { AppContext, Brands, LocationIndex } from '../../context/interfaces';
import ProgressBar from '../../components/ProgressBar';
import OptionData from '../../components/information/OptionData';
import '../../assets/styles/screens/location/Location.scss';
import { BRAND, urlIdRewrite } from 'shared/utils';
import { useParams } from 'react-router';

interface Props {
	to: string;
	locationIndex: LocationIndex; // set location
	screenIndex: number;
}

const IsDepositScreen = (props: Props): JSX.Element => {
	const ctx = useContext(Context) as AppContext;
	const { selectedIsDeposit } = ctx;
	const { id } = useParams<{ id: string }>();

	const [nextScreen, setNextScreen] = useState(urlIdRewrite(props.to, id));
	const canContinue = selectedIsDeposit != null;
	useEffect(() => {
		if (BRAND === Brands.CSM && props.locationIndex === LocationIndex.IS_DEPOSIT) {
			setNextScreen(selectedIsDeposit ? '/' + id + '/deposit/form' : nextScreen);
		}
	}, [setNextScreen, props.locationIndex, selectedIsDeposit, id, nextScreen]);

	return (
		<div className="location">
			<Breadcrumbs currentStep={props.screenIndex} />
			<ProgressBar screenIndex={props.screenIndex} />

			{props.locationIndex === LocationIndex.IS_DEPOSIT && (
				<>
					<Header title="¿Deseas depósito directo?" />
					<OptionData locationIndex={props.locationIndex} />
				</>
			)}

			<ConfirmButton to={nextScreen} disabled={!canContinue} />
		</div>
	);
};

export default IsDepositScreen;
