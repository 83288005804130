import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';
import { BRAND } from 'shared/utils';

interface ButtonProps {
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	children: ReactNode;
	danger?: boolean;
	extendedClass?: string;
}

const Button = ({ onClick, children, danger, extendedClass }: ButtonProps): JSX.Element => {
	const className = classNames(styles.button, [styles[BRAND]], {
		[styles.danger]: danger,
	});

	return (
		<button className={`${className} ${extendedClass}`} onClick={onClick}>
			{children}
		</button>
	);
};

export default Button;
