import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

interface DatePickerProps {
	value: Date | null;
	onChange: any;
	label?: string;
	maxDate?: Date | null;
}

const DatePicker = ({ value, onChange, label, maxDate = null }: DatePickerProps): JSX.Element => {
	const conditionalProps: any = {};
	if (maxDate) {
		conditionalProps.maxDate = maxDate;
	}

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				label={label}
				placeholder="Fecha"
				value={value}
				onChange={onChange}
				format="yyyy/MM/dd"
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
				inputVariant="outlined"
				fullWidth
				InputProps={{ readOnly: true }}
				data-testid="date picker"
				{...conditionalProps}
			/>
		</MuiPickersUtilsProvider>
	);
};

export default DatePicker;
