import { ReactElement, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import getDivToPortal from './utils';

interface PortalProps {
	children: ReactNode;
}

const Portal = ({ children }: PortalProps): ReactElement => {
	const element = getDivToPortal();

	return createPortal(children, element);
};

export default Portal;
