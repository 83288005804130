import React from 'react';
import clsx from 'clsx';
import { Scene } from '../../../types/scene';

import './styles.scss';

interface Props {
	selectedScene: string;
	scenesOptionsRows: Array<any>;
	onClickScene: (value: string) => void;
}

const SceneOptions = React.memo(
	(props: Props): JSX.Element => {
		return (
			<>
				{props.scenesOptionsRows.map((rowOfOptions, index) => (
					<div className="scenesRow" key={`row${index}`}>
						{rowOfOptions.map((scene: Scene) => (
							<div
								key={scene.value}
								className={clsx('sceneOption', { 'sceneOption--selected': scene.value === props.selectedScene })}
								onClick={() => props.onClickScene(scene.value)}
							>
								{scene.icon({
									className: clsx('sceneOption__icon', {
										'sceneOption__icon--selected': scene.value === props.selectedScene,
									}),
								})}
								<label className="sceneOption__text">{scene.text}</label>
							</div>
						))}
					</div>
				))}
			</>
		);
	}
);
SceneOptions.displayName = 'SceneOptions';
export default SceneOptions;
