import React, { useContext } from 'react';
import { Context } from 'context/Context';
import { AppContext, Brands } from 'context/interfaces';
import spainFlag from 'assets/icons/spain.png';
import ukFlag from 'assets/icons/united-kingdom.png';
import downChevron from 'assets/icons/down-chevron.png';
import styles from './RightsForm.module.scss';
import { BRAND } from 'shared/utils';

type LanguageType = {
	[key: string]: any;
};

type RulesTextType = {
	[key: string]: LanguageType;
};

const rulesSpanish = [
	'Derecho a elegir al asegurador e intermediario de seguros de su preferencia.',
	'Derecho a que se divulgue de forma verbal y escrita que, en relación con la extensión de un crédito por parte de una Institución Financiera, la concesión de dicho crédito no se condiciona a la compra de seguros o que se adquieran los mismos por instituciones afiliadas a dicha Institución.',
	'Derecho a exigir a que el intermediario de seguros le muestre su licencia.',
	'Derecho a que todo productor o representante autorizado identifique y mida los riesgos a los que está expuesto el asegurado, de suerte que se gestione el producto de seguros que se ajuste a su necesidad de cubierta.',
	'Derecho a que quien le gestiona su póliza le provea una orientación clara y completa sobre la cubierta, beneficios, límites y exclusiones de la póliza, así como los deberes y obligaciones del asegurado.',
	'Derecho a que las pólizas individuales sean escritas en el idioma que el asegurado escoja entre inglés o español.',
	'Derecho a obtener copia de su póliza.',
	'Derecho a que el asegurador acuse recibo de su reclamación dentro de los quince (15) días de haberse notificado.',
	'Derecho a que el asegurador actúe de buena fe, de forma justa y equitativa al evaluar y resolver su reclamación.',
	'Derecho a que el asegurador le envíe su oferta con desglose del ajuste para su evaluación, antes de recibir un cheque que usted no ha aceptado, o concurrentemente con el cheque, sin que se entienda que el simple recibo del mismo significa una renuncia a sus reclamaciones.',
	'Derecho a que el asegurador le incluya en el ajuste, las razones por las cuales ciertas partidas de la reclamación fueron declinadas.',
	'Derecho a recibir del asegurador hasta tres (3) fechas alternativas para inspección, mediante escrito a las direcciones físicas y/o electrónicas que contenga la póliza y la hoja de notificación de reclamación, en caso de no haberse podido coordinar por teléfono, esto antes de que el asegurador le pueda cerrar su reclamación.',
	'Derecho a solicitar y recibir cita para manejar su solicitud de reconsideración.',
	'Derecho a que su reclamación se resuelva en un período razonable dentro de los primeros noventa (90) días de haberse recibido la reclamación.',
	'Derecho a solicitar una reconsideración a la determinación del asegurador respecto a su reclamación, y que la misma sea atendida y resuelta dentro del término de treinta (30) días de presentada la solicitud.',
	'Derecho a radicar una solicitud de investigación ante el Comisionado de Seguros.',
	'Todo Productor y Representante Autorizado vendrá obligado a entregarle copia de los Derechos del Consumidor establecidos en este Artículo al asegurado, al gestionar una póliza de seguros, e igualmente al asegurador al presentársele una reclamación.',
];

const rulesSMES = [
	'Declaro que la Compañía Mundial de Seguros S.A. identiﬁcado con Nit. 860.037.013-6 en su calidad de Responsable del Tratamiento de Datos Personales me ha informado: 1) Que la Política de Tratamiento de Datos Personales se encuentran en la página web www.segurosmundial.com.co; 2) Que son facultativas las respuestas a las preguntas sobre datos de niñas, niños, adolescentes y aquellas que versen sobre datos sensibles y en consecuencia no he sido obligado a responderlas; 3) Que como titular de la información, me asisten los derechos previstos en las leyes 1266 de 2008 y 1581 de 2012. En especial, me asiste el derecho a conocer, actualizar, rectiﬁcar, revocar y suprimir mis datos personales.',
	'Autorizo de manera previa, expresa e informada a la Compañía Mundial de Seguros S.A. y/o cualquier sociedad controlada, directamente o indirectamente, que tengan participación accionaria o sean asociados, domiciliadas en Colombia y/o en el exterior, terceros contratados por esta o a quien la represente, en adelante LA COMPAÑÍA para que realice el tratamiento de mis datos personales para las siguientes finalidades:',
	'(i) Tramitar mi solicitud de vinculación o de prestación de una reclamación;',
	'(ii) Ejecutar obligaciones legales o contractuales derivadas del contrato de seguro;',
	'(iii) Ejecutar y cumplir los contratos que celebre LA COMPAÑÍA con entidades en Colombia o en el extranjero para cumplir con su objeto social;',
	'(iv) El control y prevención de fraudes, lavado de activos, financiación del terrorismo o financiación de la proliferación de armas de destrucción masiva;',
	'(v) Gestionar y tramitar reclamaciones y liquidar y pagar siniestros;',
	'(vi) Elaborar estudios técnico-actuariales, encuestas, análisis de tendencias de mercado y en general cualquier estudio técnico o de campo relacionado con el sector asegurador o la prestación de servicios de LA COMPAÑÍA;',
	'(vii) Consultar, almacenar, administrar, transferir, procesar y reportar mi comportamiento financiero a las Centrales de Información o bases de datos debidamente constituidas;',
	'(viii) Transferir o transmitir los datos personales a terceros contratados ubicados en el territorio nacional o en el extranjero, en cumplimiento de obligaciones legales y/o contractuales',
	'(ix) Enviar información financiera de sujetos de tributación en los Estados Unidos al IRS o a otras autoridades de Estados Unidos u otros países, en los términos del FATCA o de normas de similar naturaleza de terceros países o de tratados internacionales;',
	'(x) Tramitar y gestionar felicitaciones, solicitudes, peticiones o quejas;',
	'(xi) Recolectar, solicitar, consultar, almacenar, actualizar, usar, y conservar mis datos personales sensibles incluyendo certificado bancario, la historia clínica y datos sobre mi estado de salud, indispensables para poder ejecutar el objeto social y la actividad económica de LA COMPAÑÍA;',
	'(xii) Recolectar, consultar, almacenar, actualizar, usar, y conservar los datos personales de mis hijos menores de edad en calidad de su representante legal o tutor, siempre y cuando se cumpla con el interés prevalente del menor.',
	'LA COMPAÑÍA conservará mis datos personales mientras sea necesario para el cumplimiento de cualquier obligación legal y contractual o para la atención de cualquier queja o reclamo judicial o extrajudicial.',
	'Autorizo de manera previa, expresa e informada a Seguros Mundial para tratar mis datos personales y/o las de mi representado',
];

const rulesEnglish = [
	'Right to select the insurer and insurance intermediary of his or her preference.',
	'Right to be informed, both verbally and in a written manner that the extension of credit from a financial Institution will not be conditioned to the acquisition of insurance from that institution or its affiliates.',
	'Right to require an insurance intermediary to show his or her license',
	"Right that all producers or authorized representatives identify and assess the risks an insured is exposed to in order to find the insurance product that better fits the insured's coverage needs.",
	" Right that the persons who manages the insurance policy provides to the insured a clear and complete advice on the policy's coverage benefits. limits and exclusions as well as on the rights and obligations of the insured.",
	"Right that the individual policies be written in the language of the insured's choice, whether English or Spanish.",
	' Right to obtain a copy of his or her insurance policy',
	' Right that the insurer provides a receipt acknowledging the filing of a claim within fifteen (15) days of the notification of the claim.',
	' Right that the insurer acts in good faith and in a fair and equitable manner when assessing and deciding a claim.',
	' Right to receive from the insurer an offer with a breakdown of the adjustment for the insured consideration prior to receiving a non-agreed check or together with such check providing that the simple receipt of a non-agreed check cannot be interpreted as a waiver of the insured claims',
	' Right that the insurer includes in its adjustment the reasons for declining certain items of a claim.',
	' Right to receive from the insurer up to three (3) alternative dates for inspection, sent to the physical and/or email addresses appearing in the policy and in the claims notification, whenever no telephone coordination was possible and before the insurer can close the claim.',
	' Right to ask and receive an appointment to manage a reconsideration request',
	' Right that the claim be seated in a reasonable period of time within the first ninety (90) days from its receipt.',
	' Right to request reconsideration from the insurers determination on a claim and that this reconsideration be decided within thirty (30) days from its filing.',
	' Right to file a request for investigation to the Insurance Commissioner.',
	'All producers and authorized representatives will be required to submit a copy of the consumers rights established in this Article to the insured when managing an insurance policy and equally, to the insurer when filing a claim.',
];

const RulesText = (): RulesTextType => {
	const { injured } = useContext(Context) as AppContext;

	return {
		EN: {
			flag: ukFlag,
			textBody: (
				<div>
					{Brands.CSM === BRAND && (
						<>
							<h3 className={styles.title}>
								INSURANCE CONSUMER&lsquo;S <br /> BILL OF RIGHTS
							</h3>
							<p className={styles.abstract}>
								Pursuant to Law 14-2020, insurance consumers in Puerto Rico will enjoy all the rights vested in all
								applicable laws and regulations, including, but not limited to the following:
							</p>

							<p className={styles.info}>Slide down to see more</p>
							<div className={styles.iconContainer}>
								<img src={downChevron} className={styles.downChevron} alt="down chevron" />
							</div>
							<div className={styles.rules}>
								<ol>
									{rulesEnglish.map((rule, index) => {
										return <li key={index}>{rule}</li>;
									})}
								</ol>
							</div>
							<div>
								<p>ACKNOWLEDGE RECEIPT</p>
							</div>
							<div>
								<p>
									I {injured.fields?.firstName} {injured.fields?.lastName}, confirm that I have received from the
									Cooperativa de Seguros Múltiples de Puerto Rico (CSM) a copy of the insurance Consumers Bill of
									Rights, in compliance with Law No. 14 of January 4. 2020. This document was given to me as the
									insured/claimant of the claim corresponding to the policy.
								</p>
							</div>
						</>
					)}
					{Brands.PREMIER === BRAND && (
						<>
							<h3 className={styles.title}>
								INSURANCE CONSUMER&lsquo;S <br /> BILL OF RIGHTS
							</h3>
							<p className={styles.abstract}>
								Pursuant to Law 14-2020, insurance consumers in Puerto Rico will enjoy all the rights vested in all
								applicable laws and regulations, including, but not limited to the following:
							</p>

							<p className={styles.info}>Slide down to see more</p>
							<div className={styles.iconContainer}>
								<img src={downChevron} className={styles.downChevron} alt="down chevron" />
							</div>
							<div className={styles.rules}>
								<ol>
									{rulesEnglish.map((rule, index) => {
										return <li key={index}>{rule}</li>;
									})}
								</ol>
							</div>
							<div>
								<p>ACKNOWLEDGE RECEIPT</p>
							</div>
							<div>
								<p>
									I {injured.fields?.firstName} {injured.fields?.lastName}, confirm that I have received from the
									Premier Insurance Company (PREMIER) a copy of the insurance Consumers Bill of Rights, in compliance
									with Law No. 14 of January 4. 2020. This document was given to me as the insured/claimant of the claim
									corresponding to the policy.
								</p>
							</div>
						</>
					)}
				</div>
			),
		},
		ES: {
			flag: spainFlag,
			textBody: (
				<div>
					{Brands.CSM === BRAND && (
						<>
							<h3 className={styles.title}>
								CARTA DE DERECHOS <br />
								DEL CONSUMIDOR DE SEGUROS
							</h3>
							<p className={styles.abstract}>
								Por virtud de la Ley Núm. 14 -2020, el Consumidor de Seguros de Puerto Rico disfrutará de todos los
								derechos que le son reconocidos en las leyes y reglamentos que les sean aplicables, incluyendo, pero sin
								limitarse a los siguientes:
							</p>

							<p className={styles.info}>Deslice para ver más</p>
							<div className={styles.iconContainer}>
								<img src={downChevron} className={styles.downChevron} alt="down chevron" />
							</div>
							<div className={styles.rules}>
								<ol>
									{rulesSpanish.map((rule, index) => {
										return <li key={index}>{rule}</li>;
									})}
								</ol>
							</div>
							<div>
								<p>ACUSO DE RECIBO</p>
							</div>
							<div>
								<p>
									Yo {injured.fields?.firstName} {injured.fields?.lastName} confirmo que he recibido de parte de la
									Cooperativa de Seguros Múltiples de Puerto Rico (CSM) una copia de la “Carta de Derechos del
									Consumidor de Seguros” en cumplimiento con la Ley Núm. 14 de 4 de enero de 2020. Este documento se me
									entrega como asegurado(a)/reclamante de la reclamación correspondiente a la póliza.
								</p>
							</div>
						</>
					)}
					{Brands.PREMIER === BRAND && (
						<>
							<h3 className={styles.title}>
								CARTA DE DERECHOS <br />
								DEL CONSUMIDOR DE SEGUROS
							</h3>
							<p className={styles.abstract}>
								Por virtud de la Ley Núm. 14 -2020, el Consumidor de Seguros de Puerto Rico disfrutará de todos los
								derechos que le son reconocidos en las leyes y reglamentos que les sean aplicables, incluyendo, pero sin
								limitarse a los siguientes:
							</p>

							<p className={styles.info}>Deslice para ver más</p>
							<div className={styles.iconContainer}>
								<img src={downChevron} className={styles.downChevron} alt="down chevron" />
							</div>
							<div className={styles.rules}>
								<ol>
									{rulesSpanish.map((rule, index) => {
										return <li key={index}>{rule}</li>;
									})}
								</ol>
							</div>
							<div>
								<p>ACUSO DE RECIBO</p>
							</div>
							<div>
								<p>
									Yo {injured.fields?.firstName} {injured.fields?.lastName} confirmo que he recibido de parte de Premier
									Insurance Company (PREMIER) una copia de la “Carta de Derechos del Consumidor de Seguros” en
									cumplimiento con la Ley Núm. 14 de 4 de enero de 2020. Este documento se me entrega como
									asegurado(a)/reclamante de la reclamación correspondiente a la póliza.
								</p>
							</div>
						</>
					)}
					{(Brands.SEGUROS_MUNDIAL === BRAND || BRAND === Brands.COMFENALCO) && (
						<>
							<h3 className={styles.title}>Autorización de tratamiento de datos personales</h3>
							<p className={styles.info}>Deslice para ver más</p>
							<div className={styles.iconContainer}>
								<img src={downChevron} className={styles.downChevron} alt="down chevron" />
							</div>
							<div className={styles.rules}>
								<ol>
									{rulesSMES.map((rule, index) => {
										return <li key={index}>{rule}</li>;
									})}
								</ol>
							</div>
							<div>
								<p>ACUSO DE RECIBO</p>
							</div>
							<div>
								<p>
									Yo confirmo que he leído la <b>Autorización de tratamiento de datos personales</b>.
								</p>
							</div>
						</>
					)}

					{Brands.MULTINATIONAL === BRAND && (
						<>
							<h3 className={styles.title}>
								CARTA DE DERECHOS <br />
								DEL CONSUMIDOR DE SEGUROS
							</h3>
							<p className={styles.abstract}>
								Por virtud de la Ley Núm. 14 -2020, el Consumidor de Seguros de Puerto Rico disfrutará de todos los
								derechos que le son reconocidos en las leyes y reglamentos que les sean aplicables, incluyendo, pero sin
								limitarse a los siguientes:
							</p>

							<p className={styles.info}>Deslice para ver más</p>
							<div className={styles.iconContainer}>
								<img src={downChevron} className={styles.downChevron} alt="down chevron" />
							</div>
							<div className={styles.rules}>
								<ol>
									{rulesSpanish.map((rule, index) => {
										return <li key={index}>{rule}</li>;
									})}
								</ol>
							</div>
							<div>
								<p>ACUSO DE RECIBO</p>
							</div>
							<div>
								<p>
									Yo {injured.fields?.firstName} {injured.fields?.lastName} confirmo que he recibido de parte de
									Multinational una copia de la “Carta de Derechos del Consumidor de Seguros” en cumplimiento con la Ley
									Núm. 14 de 4 de enero de 2020. Este documento se me entrega como asegurado(a)/reclamante de la
									reclamación correspondiente a la póliza.
								</p>
							</div>
						</>
					)}
				</div>
			),
		},
	};
};

export default RulesText;
