import React from 'react';
import '../../assets/styles/screens/inactive/Inactive.scss';

const InactiveScreen = (): JSX.Element => {
	return (
		<div className="inactive-screen">
			<h1>Este enlace se encuentra inactivo.</h1>
		</div>
	);
};

export default InactiveScreen;
