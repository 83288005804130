import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Context } from '../../context/Context';
import { AppContext, LocationIndex, ActionType } from '../../context/interfaces';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import Breadcrumbs from '../../components/Breadcrumbs';
import ConfirmButton from '../../components/common/ConfirmButton';
import Header from '../../components/common/Header';
import ProgressBar from '../../components/ProgressBar';
import OptionData from '../../components/information/OptionData';
import Signature from '../../components/signature/Signature';
import '../../assets/styles/screens/deposit/DirectDeposit.scss';

interface Props {
	to: string;
	screenIndex: number;
}

const DirectDepositScreen = (props: Props): JSX.Element => {
	const ctx = useContext(Context) as AppContext;
	const { dispatch, directDeposit } = ctx;
	const { id } = useParams<{ id: string }>();
	const [canContinue, setCanContinue] = useState(false);
	const { handleSubmit, register, errors } = useForm();
	const history = useHistory();
	const buttonRef = React.createRef<HTMLInputElement>();
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const onSubmit = (values: any) => {
		const currentValues = {
			branchOffice: values.branchOffice,
			claimantName: values.claimantName,
			claimantNumber: values.claimantNumber,
			financialInstitution: values.financialInstitution,
			personalContact: values.personalContact,
			phone: values.phone,
			policy: values.policy,
			routeNumber: values.routeNumber,
			accountType: directDeposit.accountType,
			signature: directDeposit.signature,
		};
		dispatch({
			type: ActionType.SET_DIRECT_DEPOSIT,
			data: currentValues,
		});
		const uri = '/' + id + '/summary';
		history.push(uri);
	};
	const onChange = () => setCanContinue(true);
	const onError = () => setCanContinue(false);
	const handleBtnClick = (): void => {
		if (buttonRef.current) buttonRef.current.click();
	};
	const inputField = (
		name: string,
		label: string,
		isRequired: boolean,
		errorMessage?: any,
		currentValue?: string | null
	): ReactElement => {
		const classes = cn('direct-deposit-input', { 'has-error': errorMessage });
		return (
			<div className={classes}>
				<input
					data-testid="text-input"
					id={name}
					name={name}
					type="text"
					required
					defaultValue={currentValue ?? ''}
					ref={isRequired ? register({ required: 'Campo requerido' }) : register}
					onChange={onChange}
				/>
				<label>{label}</label>
				{errorMessage && <span>{errorMessage.message}</span>}
			</div>
		);
	};
	const optionField = (label: string) => {
		const classes = cn({ 'has-error': directDeposit?.accountType === null });
		if (directDeposit?.accountType === null) setCanContinue(false);
		return (
			<div className={classes}>
				<label>{label}</label>
				<OptionData locationIndex={LocationIndex.DIRECT_DEPOSIT} />
				{directDeposit?.accountType === null && <label>Campo requerido</label>}
			</div>
		);
	};
	const disclaimer = () => {
		return (
			<>
				<h4>Disclaimer</h4>
				<p>
					AUTORIZO A LA COOPERATIVA DE SEGUROS MÚLTIPLES DE PUERTO RICO (CSM) A DEPOSITAR EN LA CUENTA BANCARIA ANTES
					DESCRITA, EL IMPORTE DEL PAGO DE LA RECLAMACIÓN. ÉSTA AUTORIZACIÓN CONTINUARÁ EN EFECTO A MENOS QUE MEDIANTE
					NOTIFICACIÓN ESCRITA LA MISMA SEA REVOCADA. A SU OPCIÓN PODRÁ CAMBIAR LA INSTITUCIÓN BANCARIA Y/O EL NÚMERO DE
					CUENTA CUANDO ASÍ LO DESEE, SUSTITUYENDO ESTE FORMULARIO DE AUTORIZACIÓN.
				</p>
			</>
		);
	};
	useEffect(() => {
		if (directDeposit && directDeposit.signature?.length > 0) setCanContinue(true);
	}, [directDeposit]);
	return (
		<div className="direct-deposit">
			<Breadcrumbs currentStep={props.screenIndex} />
			<ProgressBar screenIndex={props.screenIndex} />
			<Header title="Información para depósito directo" />
			<div className="container">
				<form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
					{inputField('claimantName', 'Nombre del reclamante', true, errors.claimantName, directDeposit?.claimantName)}
					{inputField(
						'claimantNumber',
						'Número de reclamación',
						false,
						errors.claimantNumber,
						directDeposit?.claimantNumber
					)}
					{inputField('policy', 'No. de Póliza', false, null, directDeposit?.policy)}
					{inputField(
						'financialInstitution',
						'Nombre de la institución financiera',
						true,
						errors.financialInstitution,
						directDeposit?.financialInstitution
					)}
					{inputField('routeNumber', 'Número de ruta/tránsito', false, errors.routeNumber, directDeposit?.routeNumber)}
					{optionField('Tipo de cuenta')}
					{inputField('branchOffice', 'Sucursal', false, errors.branchOffice, directDeposit?.branchOffice)}
					{inputField('personalContact', 'Contacto personal', false, null, directDeposit?.personalContact)}
					{inputField('phone', 'Teléfono', false, errors.phone, directDeposit?.phone)}
					{disclaimer()}
					<h3>Firma</h3>
					<Signature locationIndex={LocationIndex.DIRECT_DEPOSIT} />
					<input ref={buttonRef} id="btnSubmit" name="btnSubmit" type="submit" />
				</form>
			</div>
			<ConfirmButton to="#" disabled={!canContinue} onClick={handleBtnClick} />
		</div>
	);
};

export default DirectDepositScreen;
