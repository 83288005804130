import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import ConfirmButton from '../../components/common/ConfirmButton';
import Header from '../../components/common/Header';
import MessageWindow from '../../components/common/MessageWindow';
import RoundButton from '../../components/RoundButton';
import { Context } from '../../context/Context';
import HeliosClient from '../../context/Helios';
import { ActionType, AppContext, DispatchFunction } from '../../context/interfaces';
import ProgressBar from '../../components/ProgressBar';
import { completeSectionGTM, initializeSectionGTM } from '../../shared/utils/gooogleTagManager';
import '../../assets/styles/screens/summary/PolicivePart.scss';
import { urlIdRewrite } from 'shared/utils';

export const setPolicivePart = (value: boolean, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_POLICIVE_PART, data: value });
	if (!value) dispatch({ type: ActionType.SET_NUMBER_PART, data: '' });
};
interface Props {
	to: string;
	screenIndex: number;
}
const PolicivePartScreen = (props: Props): JSX.Element => {
	const context = useContext(Context) as AppContext;
	const location = useLocation();
	const { id } = useParams<{ id: string }>();
	const { dispatch, typePolicivePart, policivePart, numberPart } = context;
	const [showMessageWindow, setShowMessageWindow] = useState(false);
	const [messages, setMessage] = useState({
		title: '',
		message: '',
	});
	const [initLoadGT, setInitLoadGT] = useState(true);

	const showPolicivePartWindow = (): void => {
		setMessage({
			title: 'Documento - Parte Policial',
			message: 'En la sección de fotos debe capturar el documento legiblemente.',
		});
		setShowMessageWindow(true);
	};

	const updateNumberPart = (e: any): void => {
		dispatch({ type: ActionType.SET_NUMBER_PART, data: e.target.value });
	};

	const submitPolicivePart = (): void => {
		completeSectionGTM(context, location.pathname);
		new HeliosClient().saveInspection(context, id);
	};

	const canContinue =
		typePolicivePart !== undefined &&
		typePolicivePart?.length > 0 &&
		policivePart !== null &&
		(policivePart === false || (policivePart === true && numberPart != null && numberPart.length > 0));

	useEffect(() => {
		if (initLoadGT) {
			initializeSectionGTM(context, location.pathname);
			setInitLoadGT(false);
		}
	}, [initLoadGT, context, location.pathname]);

	return (
		<div className="responsibility-screen">
			<Breadcrumbs currentStep={props.screenIndex} />
			<ProgressBar screenIndex={props.screenIndex} />
			<Header title="¿Hubo Parte Policial?" subtitle="Si hubo Parte Policial indique el No. de Parte." />
			<div className="container">
				<div className="responsible-options">
					<RoundButton
						checked={typePolicivePart === 'Si' && policivePart === true}
						onClick={(): void => {
							setPolicivePart(true, dispatch);
							showPolicivePartWindow();
						}}
					>
						Si
					</RoundButton>
					<RoundButton
						checked={typePolicivePart === 'No' && policivePart === false}
						onClick={(): void => {
							setPolicivePart(false, dispatch);
						}}
					>
						No
					</RoundButton>
				</div>
				<div className="text-input">
					<input
						data-testid="text-input"
						id="txtNumberPart"
						type="text"
						readOnly={policivePart === false}
						value={numberPart}
						onChange={updateNumberPart}
						required
					/>
					<label htmlFor="txtNumberPart">Ingrese el número de parte</label>
				</div>
			</div>

			<ConfirmButton to={urlIdRewrite(props.to, id)} disabled={!canContinue} onClick={submitPolicivePart} />
			{showMessageWindow && (
				<MessageWindow
					message={messages.message}
					title={messages.title}
					dismiss={(): void => setShowMessageWindow(false)}
				></MessageWindow>
			)}
		</div>
	);
};

export default PolicivePartScreen;
