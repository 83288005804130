import React, { ChangeEvent } from 'react';
import { Option } from './../../../context/interfaces';
import './styles.scss';

const NO_VALUE = 'NO_VALUE';

interface Props {
	label: string;
	options: Array<Option>;
	value: string;
	defaultValue?: string;
	onChangeOption: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const Dropdown = (props: Props): JSX.Element => {
	return (
		<div className="dropdown">
			<label className="dropdown__label" htmlFor={props.label}>
				{props.label}
			</label>
			<select
				className="dropdown__select"
				value={props.value}
				defaultValue={props.defaultValue}
				onChange={props.onChangeOption}
			>
				{!props.defaultValue && !props.value && (
					<option key={NO_VALUE} value={NO_VALUE}>
						-
					</option>
				)}
				{props.options &&
					props.options.map((option) => {
						return (
							<option key={option.id} value={option.id}>
								{option.name}
							</option>
						);
					})}
			</select>
		</div>
	);
};

export default Dropdown;
