import { Sketch } from '@connect-assistance/connect-react-components-lib';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { urlIdRewrite } from 'shared/utils';
import '../../assets/styles/screens/insured/Sketch.scss';
import Breadcrumbs from '../../components/Breadcrumbs';
import ProgressBar from '../../components/ProgressBar';
import { Context } from '../../context/Context';
import HeliosClient from '../../context/Helios';
import { ActionType, AppContext } from '../../context/interfaces';
import { completeSectionGTM, initializeSectionGTM } from '../../shared/utils/gooogleTagManager';

const GOOGLE_MAPS_KEY = process.env.REACT_APP_GMAPS_KEY || '';

interface Props {
	to: string;
	screenIndex: number;
}

const SketchScreen = (props: Props): JSX.Element => {
	const ctx = useContext(Context) as AppContext;
	const history = useHistory();
	const location = useLocation();
	const { id } = useParams<{ id: string }>();
	const [initLoadGT, setInitLoadGT] = useState(true);

	useEffect(() => {
		if (initLoadGT) {
			initializeSectionGTM(ctx, location.pathname);
			setInitLoadGT(false);
		}
	}, [initLoadGT, ctx, location.pathname]);

	return (
		<div className="sketch-screen">
			<Breadcrumbs currentStep={props.screenIndex} />
			<ProgressBar screenIndex={props.screenIndex} />

			<Sketch
				coords={ctx.location.originalCoords}
				onConfirm={(sketchImg: string): void => {
					completeSectionGTM(ctx, location.pathname);
					ctx.dispatch({ type: ActionType.SET_SKETCH, data: { dataUrl: sketchImg } });
					history.push(urlIdRewrite(props.to, id));
					new HeliosClient().saveInspection(ctx, id);
				}}
				sceneType={ctx.sketch.sceneType}
				googleMapsApiKey={GOOGLE_MAPS_KEY}
			/>
		</div>
	);
};

export default SketchScreen;
