import classNames from 'classnames';
import React from 'react';
import '../assets/styles/components/RoundButton.scss';

interface RoundButtonProps {
	checked: boolean;
	children: string;
	onClick: () => void;
}

const RoundButton = (props: RoundButtonProps): JSX.Element => {
	return (
		<button className={classNames('round-btn', { checked: props.checked })} onClick={props.onClick}>
			{props.children}
		</button>
	);
};

export default RoundButton;
