import React from 'react';
import clsx from 'clsx';
import { ScenePhoto } from '../../../types/scene-photo';

import './styles.scss';

interface Props {
	selectedScene: string;
	scenesPhotosRows: Array<any>;
	onClickScene: (value: string) => void;
}

const ScenePhotoOptions = React.memo(
	(props: Props): JSX.Element => {
		return (
			<>
				{props.scenesPhotosRows.map((rowOfPhotos, index) => (
					<div className="scenesRow" key={`row${index}`}>
						{rowOfPhotos.map((scene: ScenePhoto) => (
							<div
								key={scene.value}
								className={clsx('photoOption', { 'photoOption--selected': scene.value === props.selectedScene })}
								onClick={() => props.onClickScene(scene.value)}
							>
								<div
									className={clsx('photoOption__label', {
										'photoOption__label--selected': scene.value === props.selectedScene,
									})}
								>
									{scene.letter}
								</div>
								<img className="photoOption__icon" src={scene.icon} alt={scene.value} />
							</div>
						))}
					</div>
				))}
			</>
		);
	}
);
ScenePhotoOptions.displayName = 'ScenePhotoOptions';
export default ScenePhotoOptions;
