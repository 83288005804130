import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../context/Context';
import { ActionType, AppContext, IndividualStateKey, ScreenIndex, Brands } from '../../context/interfaces';
import { vehicleDiagram } from '../../context/Utils';
import DamageCheck from './DamageCheck';
import DamageWindow from './DamageWindow';
import { initFlexSpacers } from './Utils';
import { BRAND } from 'shared/utils';
import '../../assets/styles/components/vehicle/Damages.scss';
import { useHistory } from 'react-router';

const AREAS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

interface Props {
	screenKey?: ScreenIndex;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VehicleDamages = (props: Props): JSX.Element => {
	// Get state for this particular prop.individual
	const context = useContext(Context) as AppContext;
	const history = useHistory();
	const currentStateKey = history.location.pathname.split('/')[2] as IndividualStateKey;
	const { dispatch } = context;
	const { damages } = context[currentStateKey] || {};

	const [damageWindowId, setDamageWindowId] = useState<number | null>(null);
	const [damageList, setDamageList] = useState<string[] | null>(null);
	const flexSpacers = initFlexSpacers();

	const selected = (areaId: number): void => {
		switch (BRAND) {
			case Brands.LAFISE: {
				alternativeSetDamage(areaId);
				break;
			}
			default: {
				setDamageWindowId(areaId);
				break;
			}
		}
	};

	const alternativeSetDamage = (areaId: number): void => {
		let currentDamages = ['Daños'];
		if (areaId in damages) currentDamages = [];
		dispatch({
			type: ActionType.SET_DAMAGE,
			data: { stateKey: currentStateKey, areaId, damageTypes: currentDamages },
		});
	};

	const cancel = (): void => {
		setDamageWindowId(null); // close modal
	};

	const onConfirm = (areaId: number, damageTypes: string[]): void => {
		setDamageWindowId(null);
		dispatch({ type: ActionType.SET_DAMAGE, data: { stateKey: currentStateKey, areaId, damageTypes: damageTypes } });
	};

	useEffect(() => {
		if (BRAND === Brands.OCEANICA) {
			const currentDamageTypes = ['Rayas', 'Abolladura o golpe', 'Daños mecánicos', 'Rotura de cristales o vidrio'];
			setDamageList(currentDamageTypes);
		}
	}, [setDamageList]);

	return (
		<div className="vehicle-damages">
			<div className="damages-container">
				<img src={vehicleDiagram} alt="" />
				<div className="checks-container">
					<div className="damages-checks">
						{AREAS.map((areaId: number) => (
							<DamageCheck
								key={areaId}
								areaId={areaId}
								selected={areaId in damages}
								onClick={(): void => selected(areaId)}
							/>
						))}
						{flexSpacers}
					</div>
				</div>
			</div>
			<DamageWindow
				show={damageWindowId !== null}
				areaId={damageWindowId || 0}
				damageTypes={damages[damageWindowId || 0]}
				damageList={damageList}
				close={cancel}
				onConfirm={onConfirm}
			/>
		</div>
	);
};

export default VehicleDamages;
