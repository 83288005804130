import React, { useContext, useEffect, useRef, useState, SyntheticEvent } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Context } from '../../context/Context';
import { ActionType, AppContext, LocationIndex } from '../../context/interfaces';
import useWindowDimensions from '../../screens/summary/useWindowDimensions';
import ConfirmButton from '../common/ConfirmButton';
import { cancelCircleIcon, rotateIcon, editIcon } from '../../context/Utils';
import '../../assets/styles/components/signature/Signature.scss';

const PADDING = 16; // keep in sync with .scss $padding.

async function resizeImage(dataUrl: string, width: number, height: number): Promise<string> {
	const sourceImage = new Image();

	// eslint-disable-next-line no-undef
	return new Promise((resolve) => {
		sourceImage.onload = function () {
			// Create a canvas with the desired dimensions
			const canvas = document.createElement('canvas');
			canvas.width = width;
			canvas.height = height;

			// Scale and draw the source image to the canvas
			canvas.getContext('2d')?.drawImage(sourceImage, 0, 0, width, height);

			// Convert the canvas to a data URL in PNG format
			resolve(canvas.toDataURL());
		};

		sourceImage.src = dataUrl;
	});
}

/**
 * Provides a Preview div, with edit and clear options. Hitting clear
 * will create a full-screen landscape oriented canvas to hold signature.
 *
 * NOTE: We investigated using ScreenOrientation.lock, but its not supported
 * in a good amount of devices and its considered experimental. Indeed hooks
 * like "useScreenOrientation" would break in iOS devices. We also tried
 * creating a rotated view by using CSS rotate(90deg), but it got complicated
 * quick, considering resizing/re-orientation causes the browser to clear
 * the canvas, and the canvas only works upright. So it've involved considerable
 * "magic" to redraw the signature on resizing/re-orientation and/or handling
 * before sending to server. Scrapped approach.
 *
 * CSS orientation media queries work fine on most devices.
 */
interface Props {
	locationIndex?: LocationIndex;
}
export const Signature = (props: Props): JSX.Element => {
	const { dispatch, signature, directDeposit } = useContext(Context) as AppContext;
	const [inEditMode, setInEditMode] = useState<boolean>(false);
	const [showHelpMessage, setShowHelpMessage] = useState<boolean>(true);
	const [previewDataUrl, setPreviewDataUrl] = useState<string | null>(null);

	const signatureRef = useRef<SignatureCanvas>(null);
	const { height, width } = useWindowDimensions();

	const setSignature = (dataUrl: string | null): void => {
		if (!props.locationIndex) dispatch({ type: ActionType.SET_SIGNATURE, data: dataUrl });
		if (props.locationIndex === LocationIndex.DIRECT_DEPOSIT)
			dispatch({ type: ActionType.SET_DEPOSIT_SIGNATURE, data: dataUrl });
	};

	const onEditClick = (): void => setInEditMode(true);

	const onClearClick = (e: SyntheticEvent): void => {
		setSignature(null);
		signatureRef.current?.clear();
		setShowHelpMessage(true);
		e.stopPropagation();
	};
	const onConfirmClick = (): void => setInEditMode(false);

	const onSignatureBegin = (): void => setShowHelpMessage(false);

	const onSignatureEnd = (): void => {
		if (signatureRef.current === null) return;
		const dataUrl = signatureRef.current.toDataURL();
		setSignature(dataUrl);
	};

	useEffect(() => {
		if (!props.locationIndex && signature !== null)
			resizeImage(signature, width - PADDING - PADDING, 100).then(setPreviewDataUrl);
		if (props.locationIndex === LocationIndex.DIRECT_DEPOSIT && directDeposit.signature !== null)
			resizeImage(directDeposit.signature, width - PADDING - PADDING, 100).then(setPreviewDataUrl);
		else setPreviewDataUrl(null);
	}, [signature, width, height, directDeposit, props.locationIndex]);

	return (
		<div className="signature-container">
			{inEditMode && (
				<div className="fullscreen-container">
					<div className="canvas-container">
						{showHelpMessage && <div className="help-text">Firme con su dedo</div>}
						<SignatureCanvas
							penColor="black"
							ref={signatureRef}
							canvasProps={{ width: width - 100, height: 150, className: 'sig-canvas' }}
							clearOnResize={false}
							onBegin={onSignatureBegin}
							onEnd={onSignatureEnd}
						/>
						<img src={cancelCircleIcon} className="clear-icon" alt="clear-icon" onClick={onClearClick} />
					</div>
					<ConfirmButton to="#" onClick={onConfirmClick} disabled={signature === null} />
					<img src={rotateIcon} className="rotate-icon" alt="rotate-icon" />
					<h2>Por favor rotar su dispositivo</h2>
				</div>
			)}
			{!inEditMode && (
				<div className="preview" onClick={onEditClick}>
					{previewDataUrl && <img src={previewDataUrl} alt="signature preview" />}
					<img src={editIcon} className="edit-icon" alt="edit-icon" />
					<img src={cancelCircleIcon} className="clear-icon" alt="clear-icon" onClick={onClearClick} />
				</div>
			)}
		</div>
	);
};

export default Signature;
