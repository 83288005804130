import Breadcrumbs from 'components/Breadcrumbs';
import React, { FC, useContext, useEffect, useState } from 'react';
import 'assets/styles/screens/survey/Survey.scss';
import ProgressBar from 'components/ProgressBar';
import { Context } from 'context/Context';
import { AppContext } from 'context/interfaces';
import { uploadSurvey } from 'context/Utils';
import { SurveyPayload } from 'types/survey';
import { completeSectionGTM, initializeSectionGTM, surveyReviewGTM } from 'shared/utils/gooogleTagManager';
import HeliosClient from 'context/Helios';
import { useLocation, useParams } from 'react-router';
import { urlIdRewrite } from 'shared/utils';
import BodySurvey from './BodySurvey';

interface SurveyProps {
	screenKey: any;
	to: string;
}

const Survey: FC<SurveyProps> = ({ screenKey, to }: SurveyProps) => {
	const [initLoadGT, setInitLoadGT] = useState(true);

	const context = useContext(Context) as AppContext;
	const locationPath = useLocation();
	const { id } = useParams<{ id: string }>();

	const handleSubmit = async (data: SurveyPayload): Promise<void> => {
		if (context.eventId) {
			const body: SurveyPayload = {
				inspectionId: context.eventId,
				comment: data.comment,
				recommend: data.recommend,
				experience: data.experience,
				indInconvenient: data.indInconvenient,
				inconvenient: data.inconvenient,
				agentGrade: data.agentGrade,
			};

			//Validate if user filled some value
			if (body.experience > 0 || body.recommend > 0 || data.comment.trim().length > 0) {
				await uploadSurvey(body);

				//Send To Google Tag Manager
				surveyReviewGTM(context, data.experience, data.recommend);
			}

			submitVI();
		}
	};

	const submitVI = (): void => {
		completeSectionGTM(context, locationPath.pathname);
		new HeliosClient().finishInspection(context, id);
	};

	useEffect(() => {
		if (initLoadGT) {
			initializeSectionGTM(context, locationPath.pathname);
			setInitLoadGT(false);
		}
	}, [initLoadGT, context, locationPath.pathname]);

	return (
		<div className="survey-screen">
			<div className="survey-screen__header">
				<Breadcrumbs currentStep={screenKey} />
				<ProgressBar screenIndex={screenKey} />
				<h2>Cuéntanos tu experiencia</h2>
			</div>
			<BodySurvey handleSubmit={handleSubmit} urlToRedirect={urlIdRewrite(to, id)} />
		</div>
	);
};

export default Survey;
