import { getCodeLocations } from 'context/Utils';
import { DispatchFunction, ActionType } from '../../context/interfaces';

export const updateLatLng = async (
	{ latLng }: any,
	dispatch: DispatchFunction,
	reverseGeocode?: (lat: string, lng: string) => Promise<any>,
	reverseGeoArea?: (lat: string, lng: string) => Promise<any>
): Promise<void> => {
	const lat = latLng.lat();
	const lng = latLng.lng();
	dispatch({
		type: ActionType.SET_COORDS,
		data: { lat, lng },
	});
	if (!reverseGeocode) return;
	const address = await reverseGeocode(lat, lng);
	dispatch({ type: ActionType.SET_ADDRESS, data: address });
	dispatch({ type: ActionType.SET_EXACT_DIRECTION, data: address });

	if (!reverseGeoArea) return;
	const zoneLocation = await reverseGeoArea(lat, lng);
	const findLocations = await getCodeLocations(zoneLocation.state, zoneLocation.city, zoneLocation.neigh);
	dispatch({ type: ActionType.SET_LOCATION_ZONES, data: findLocations });
};
