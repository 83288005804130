import React from 'react';
import { DamageAreas } from '../../context/interfaces';

export const initFlexSpacers = (): JSX.Element[] => {
	const flexSpacers: JSX.Element[] = [];
	for (let i = 1; i <= 17; i++) flexSpacers.push(<div key={i} className="flex-fix"></div>);
	return flexSpacers;
};

export const getDamageAreaAsNumber = (area: DamageAreas): number => {
	switch (area) {
		case DamageAreas.LEFT_SIDE:
			return 1;
		case DamageAreas.RIGHT_SIDE:
			return 2;
		case DamageAreas.FRONT_END:
			return 3;
		case DamageAreas.REAR_END:
			return 4;
		case DamageAreas.TOP:
		default:
			return 5;
	}
};
export const getDamageArea = (index: number): DamageAreas => {
	switch (index) {
		case 1:
		case 2:
		case 14:
		case 15:
			return DamageAreas.FRONT_END;
		case 3:
		case 4:
		case 5:
		case 6:
			return DamageAreas.RIGHT_SIDE;
		case 7:
		case 8:
		case 9:
		case 19:
			return DamageAreas.REAR_END;
		case 10:
		case 11:
		case 12:
		case 13:
			return DamageAreas.LEFT_SIDE;
		case 16:
		case 17:
		case 18:
		default:
			return DamageAreas.TOP;
	}
};
