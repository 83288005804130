import { Rating } from '@material-ui/lab';
import React, { FunctionComponent, useState } from 'react';
import { ReactComponent as SurveyMarker } from 'assets/icons/marker_VI.svg';
import SurveyAgent from 'assets/icons/Agente.png';
import { SurveyPayload } from 'types/survey';
import ConfirmButton from 'components/common/ConfirmButton';
import { Brands } from 'context/interfaces';
import SMSurvey from './forms/SMSurvey';
import DefaultSurvey from './forms/Default';
import { genResumeEmail } from 'context/Utils';

interface BodyProps {
	handleSubmit: (data: SurveyPayload) => void;
	urlToRedirect: string;
}

const BRAND = process.env.REACT_APP_BRAND?.toLocaleLowerCase();

export const BodySurvey: FunctionComponent<BodyProps> = ({ handleSubmit, urlToRedirect }: BodyProps): JSX.Element => {
	const [recommend, setRecommend] = useState<number | null>(null);
	const [experience, setExperience] = useState<number | null>(null);
	const [agentGrade, setAgentGrade] = useState<number | null>(null);
	const [comment, setComment] = useState<string>('');
	const [indInconvenient, setIndInconvenient] = useState<string>('');
	const [inconvenient, setInconvenient] = useState<string>('');
	const [isVisible, setIsVisible] = useState<boolean>(true);
	const eventRecord = JSON.parse(localStorage.getItem('state') || '{}').eventId;

	const handleFormSubmit = () => {
		handleSubmit({
			recommend: Number(recommend),
			comment: comment,
			experience: Number(experience),
			inconvenient: inconvenient,
			indInconvenient: indInconvenient,
			inspectionId: 0,
			agentGrade: agentGrade === null ? 0 : agentGrade,
		});
		genResumeEmail(eventRecord);
	};

	const handleChange = (value: string) => {
		if (value === 'Si') setIsVisible(false);
		if (value === 'No') setIsVisible(true);
		setIndInconvenient(value);
	};

	const handleChangeRecommended = (value: number) => {
		setRecommend(Number(value));
	};

	return (
		<>
			<div className="survey-container">
				<form className="questions" onSubmit={handleFormSubmit}>
					{BRAND !== Brands.SEGUROS_MUNDIAL && BRAND !== Brands.COMFENALCO && (
						<>
							<hr className="rate-divider" />
							<div className="rate-examples">
								<div className="rate-example">
									<Rating value={1} readOnly size="large" />
									<span className="rate-label">= Pobre</span>
								</div>
								<div className="rate-example">
									<Rating value={5} readOnly size="large" />
									<span className="rate-label">= Excelente</span>
								</div>
							</div>
							<hr className="rate-divider" />
							<div className="question">
								<div className="question__rate">
									<p className="question__text">¿Cómo calificarías tu experiencia con esta aplicación?</p>
									<Rating
										name="experience"
										value={experience}
										size="large"
										onChange={(event: React.ChangeEvent<{}>, newValue: number | null) => {
											setExperience(newValue);
										}}
									/>
								</div>
								<div className="question__image">
									<SurveyMarker />
								</div>
							</div>
							<hr className="rate-divider" />
							<div className="question">
								<div className="question__rate">
									<p className="question__text">¿Cómo calificarías al coordinador de servicio?</p>
									<Rating
										name="agentGrade"
										value={agentGrade}
										size="large"
										onChange={(event: React.ChangeEvent<{}>, newNote: number | null) => {
											setAgentGrade(newNote);
										}}
									/>
								</div>
								<div className="question__image">
									<img src={SurveyAgent} width="84px" height="82px" alt="agent" />
								</div>
							</div>
						</>
					)}

					{(BRAND === Brands.SEGUROS_MUNDIAL || BRAND === Brands.COMFENALCO) && (
						<SMSurvey
							handleChange={handleChange}
							handleChangeRecommended={handleChangeRecommended}
							inconvenient={inconvenient}
							setInconvenient={setInconvenient}
							setExperience={setExperience}
							isVisible={isVisible}
						/>
					)}
					{BRAND !== Brands.SEGUROS_MUNDIAL && BRAND !== Brands.COMFENALCO && (
						<DefaultSurvey recommend={recommend} setRecommend={setRecommend} />
					)}
					<hr className="section-divider" />
					<div className="question--comment">
						<p className="question__text">Comentarios adicionales:</p>
						<textarea rows={5} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
					</div>
				</form>
			</div>

			<ConfirmButton text="Finalizar" to={urlToRedirect} onClick={handleFormSubmit} />
		</>
	);
};

export default BodySurvey;
